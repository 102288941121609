import React from "react";
import {newProfile, updateProfile} from "./../services/Apis";
import Broadcast from './../services/Broadcast';
import formState from "./FormState";
import { fields } from "./../forms/UserForm";
import {AccountContext} from './../contexts/Account';
const broadcast = new Broadcast("z1-on-loading");

export default function UserState(user={}){
	const {data, error, onChange, hasChanged, init} = formState(fields, user);
	const {stripeAuth} = React.useContext(AccountContext);
	const create = async()=>{
		const res = await newProfile({body:data}).catch(err=>null);
		return res;
	}

	const setup = (_user)=>{
		user = _user;
		init(fields, _user);
	}

	const update = async()=>{
		if(!hasChanged()) return {success:1};
		const body ={...data};
		if(user.privateStripe && !body.privateStripe){
			delete body.privateStripe;
			body.unsetStripeAccount = true;
		}
		const res = await updateProfile({body}).catch(err=>null);
		return res;
	}

	const submit = async()=>{
		console.log("data.privateStripe::", data.privateStripe);
		console.log("user.privateStripe::", user.privateStripe)
		let res;
		broadcast.postMessage(true);
		if(user._id || user.id){
			res = await update().catch(err=>null);
			if(res) res = {id: user.id||user._id};
		} 
		else res = await create().catch(err=>null);
		if(!res) return broadcast.postMessage(false);
		if(data.privateStripe && !user.privateStripe) return await stripeAuth(res.id || res._id);
		broadcast.postMessage(false);
		if(!user.id && !user._id) return window.location.href="/login";
		else return window.location.href="/profile";
	}

	return {data, error, onChange, submit, setup};
}