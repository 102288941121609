import React from 'react';
import {api} from "./../services/ResourceService";
import Broadcast from "./../services/Broadcast";
const authenticateChannel = new Broadcast("z1-authenticate-channel");
const AccountContext = React.createContext();
const AccountProvider = ({children})=>{
	const [account, setAccount] = React.useState();
	const [accessToken, setAccessToken] = React.useState(window.localStorage.getItem(process.env.REACT_APP_BROWSER_TOKEN_KEY));
	
	const isLoggedIn = ()=>(accessToken?true:false);
	
	const profile = async ()=>{
		const res = await api.get('/users/profile').catch(err=>logout());
		setAccount(res);
	}	

	const setLastVisitPage = ()=>sessionStorage.setItem(process.env.REACT_APP_BROWSER_LAST_VISIT_KEY, window.location.pathname);
	

	const getLastVisitPage = ()=>(sessionStorage.getItem(process.env.REACT_APP_BROWSER_LAST_VISIT_KEY) ||"/")

	const login = async ({confirmationCode, confirmationToken})=>{
		const res = await api.post('/token', {confirmationCode, confirmationToken}).catch(err=>null);
		if(!res || !res.accessToken) return; 
		window.localStorage.setItem(process.env.REACT_APP_BROWSER_TOKEN_KEY, res.accessToken);
		setAccessToken(res.accessToken);
		return true;
	}

	const logout = ()=>{
		window.localStorage.removeItem(process.env.REACT_APP_BROWSER_TOKEN_KEY);
		setAccount();
		setAccessToken();
		window.location.href = "/";
	}

	const code = async(email)=>(api.post('/auth', {email}));


	const isRegister = async(email)=>{
		const res = await api.get(`/users/count?email=${email}`).catch(err=>null);
		if(!res) return 0;
		return res.count||0;
	}

	const stripeAuth = async (id)=>{
		const res = await api.get(`/users/${id}/stripe_auth`).catch(err=>null);
		if(!res || !res.url) return;
		window.location.href =  res.url;
	}

	const stripeToken = async (id, code)=>(api.post(`/users/${id}/stripe_token`, {code}));

	React.useEffect(()=>{
		if(accessToken) profile();
		authenticateChannel.onMessage(val=>{
			if(val=="logout") logout();
		})
		return ()=>authenticateChannel.removeChannel();
	}, [accessToken]);

	return (<AccountContext.Provider value={{account, accessToken, profile, isLoggedIn, login, logout, code, isRegister, stripeAuth, stripeToken, setLastVisitPage, getLastVisitPage}}>{children}</AccountContext.Provider>);
}

export {AccountProvider, AccountContext}; 