import React from "react";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import {Stack, List, ListItem, ListItemButton, ListItemText, Checkbox, Skeleton, Typography, Button, Divider} from '@mui/material';
import {StripeListingContext} from "./../../contexts/StripeListingContext";
import { useNavigate } from "react-router-dom";

const SkeletonRow = ()=> <ListItem primary={<Skeleton variant="rectangular" height={18} width={200}/>} secondary={<Skeleton variant="rectangular" height={12} width={80}/>}></ListItem>

const Row = ({data, onCheckbox})=>{
	const {selected} = React.useContext(StripeListingContext);
	const [check, setCheck] = React.useState(selected[data.id]?true:false);
	const onClick = ()=>{
		setCheck(!check);
		onCheckbox(data);
	}

	return (
	 <>
		{!data?<SkeletonRow/>:
			<ListItemButton onClick={onClick} sx={{borderRadius:"20px", margin:"0 10px"}}>
				<Checkbox
                  edge="start"
                  checked={check}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={data.name} secondary={data.price} />
			</ListItemButton>
		}
	 </>)
}

export default function StripeBooks({back, next}){
	const {getProducts, onCheckbox, data, selectedProducts} = React.useContext(StripeListingContext);
	const [noListing, setNoListing] = React.useState(false);
	const navigate = useNavigate();

	const init = async()=>{
		if(noListing) setNoListing(false);
		const res = await getProducts();
		if(!res.length) setNoListing(true);
	}

	React.useEffect(()=>{
		init();
	},[]);
	return (
	   <Stack sx={{width: "100%", height:"100%"}} className="flex-center" direction="column"> 
	   	<Typography variant="h6">Select books to import</Typography>	
		<List  sx={{ margin:5, width: '100%', maxWidth: 600, bgcolor: 'background.paper', height:400, borderRadius:"20px", overflow:"auto", position:"relative"}}>
	        {data.map((item, index)=><Row data={item} key={data.id} onCheckbox={onCheckbox} />)}
		    {noListing?(<Stack sx={{width: "100%", height:"100%", position:"absolute", top:0, left: 0}} className="flex-center" direction="column"><Typography sx={{color:"gray"}}>No Books Found!</Typography></Stack>):(<></>)}

	    </List>
	    <Stack className="flex-center" direction="row">
	    	<Button size="large" onClick={()=>navigate('/listing')}>Cancel</Button>
	    	<Button className="yellow" disabled={selectedProducts().length?false:true} size="large" variant="contained" onClick={next} sx={{fontWeight: "300", lineHeight: "1.3"}}>Continue</Button>
	    </Stack>
	   </Stack>
	)
}