import {api} from "./ResourceService";

export const auth  			=({params, body})=>api.post('/auth', body)		
export const login 			=({params, body})=>api.post(`/token`, body)
export const userExists   	=({params, body})=>api.get(`/users/count?email=${params.email}`)
export const userProfile	=({params, body})=>api.get(`/users/profile`)
export const newProfile		=({params, body})=>api.post(`/users`, body)
export const updateProfile	=({params, body})=>api.put(`/users/profile`, body)
export const stripeAuth		=({params, body})=>api.get(`/users/${params.id}/stripe_auth`)
export const stripeToken	=({params, body})=>api.post(`/users/${params.id}/stripe_token`, body)
export const stripeBooks	=({params, body})=>api.get(`/s/products?${params.search?(params.search+"&"):""}limit=${params.limit}&offset=${params.offset}`)
export const getBooks		=({params, body})=>api.get(`/products?status=publish&orderBy=_id:>`)
export const getBook		=({params, body})=>api.get(`/products/${params.id}`)
export const checkout		=({params, body})=>api.post(`/products/${params.id}/checkout`, body)
export const redeem			=({params, body})=>api.post(`/products/redeem`, body)
export const newTransaction	=({params, body})=>api.post(`/products/${params.id}/transactions`, body)
export const listing		=({params, body})=>api.get(`/products?createdBy=${params.createdBy}&orderBy=_id:>`)
export const newListing		=({params, body})=>api.post(`/products`, body)
export const importListing	=({params, body})=>api.post(`/products/import`, body)
export const updateListing	=({params, body})=>api.put(`/products/${params.id}`, body)
export const uploadListingMedia	=({params, body})=>api.put(`products/${params.id}/uploadMedia`, body)
export const deleteListingMedia	=({params, body})=>api.put(`products/${params.id}/deleteMedia`, body)
export const getCustomerTransactions =({params, body})=>api.get(`/transactions?walletAddress=${params.walletAddress}&limit=${params.limit}&offset=${params.offset}&orderBy=_id:>`)
export const getTransactions   	=({params, body})=>api.get(`/transactions?status=${params.status}&limit=${params.limit}&offset=${params.offset}&orderBy=_id:>`)
export const updateTransaction  =({params, body})=>api.put(`/transactions/${params.id}`, body)
export const getCount  			=()=>api.totalCount
