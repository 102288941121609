import { StargateClient } from "@cosmjs/stargate";
import {api} from "./../ResourceService";

const EXPONENT = 1e9;

export default class Keplr{
	constructor(chainIds, networks){
		this.selectedChain = chainIds[0] ||"";
		this.chainIds = chainIds;
		this.networks = networks;
		this.account = null;
		this.client = null;
	}

	async onChainChanged(id){
		if(this.chainIds.indexOf(id)==-1) return api.handleError(new Error("invalid chain id"));
		await this.setup(id);
	}

	isWalletInstalled(){
		return window.keplr?true:false;
	}

	async setup(chainId = this.chainIds[0]){
		if(!this.isWalletInstalled()) return api.handleError(new Error("Keplr is not installed to your browser, please install the addon to continue"));
		this.selectedChain = chainId;
		await window.keplr.experimentalSuggestChain({
			"chainId": "likecoin-mainnet-2",
			"chainName": "LikeCoin",
			"chainSymbolImageUrl": "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/likecoin-mainnet/chain.png",
			"rpc": "https://mainnet-node-rpc.like.co",
			"rest": "https://mainnet-node.like.co",
			"nodeProvider": {
			  "name": "like.co",
			  "email": "team@like.co",
			  "website":"https://like.co/"
			},
			"walletUrlForStaking": "https://dao.like.co/validators/",
			"bip44": {
			  "coinType": 118
			},
			"bech32Config": {
			  "bech32PrefixAccAddr": "like",
			  "bech32PrefixAccPub": "likepub",
			  "bech32PrefixValAddr": "likevaloper",
			  "bech32PrefixValPub": "likevaloperpub",
			  "bech32PrefixConsAddr": "likevalcons",
			  "bech32PrefixConsPub": "likevalconspub"
			},
			"currencies": [
			  {
				"coinDenom": "LIKE",
				"coinMinimalDenom": "nanolike",
				"coinDecimals": 9,
				"coinGeckoId": "likecoin"
			  }
			],
			"feeCurrencies": [
			  {
				"coinDenom": "LIKE",
				"coinMinimalDenom": "nanolike",
				"coinDecimals": 9,
				"coinGeckoId": "likecoin",
				"gasPriceStep": {
				  "low": 1,
				  "average": 10,
				  "high": 1000
				}
			  }
			],
			"stakeCurrency": {
			  "coinDenom": "LIKE",
			  "coinMinimalDenom": "nanolike",
			  "coinDecimals": 9,
			  "coinGeckoId": "likecoin"
			},
			"features": [
			]
	});
		await window.keplr.enable(chainId); 
		const offlineSigner = await window.getOfflineSigner(chainId);
        const keplrAccounts = await offlineSigner.getAccounts();
        this.account = keplrAccounts[0];
        this.client = await StargateClient.connect(this.networks[chainId].rpc);
	}

	walletAddress(){
		if(!this.account) return "";
		return this.account.address;
	}

	async walletBalance(){
		if(!this.client || this.account) return "";
		const res = await this.client.getBalance(this.walletAddress());
		return res*1/EXPONENT;
	}
}