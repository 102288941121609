import APIService from './APIService';
import Broadcast from "./Broadcast";

const broadcast = new Broadcast("z1-authenticate-channel");
const errorBC = new Broadcast("z1-system-error");

class ResourceService extends APIService{
	constructor(resource){
		super(process.env.REACT_APP_SERVER_URL||"http://localhost:8000");
		this.path = resource ||"";
		this.tokenKey = process.env.REACT_APP_BROWSER_TOKEN_KEY;
		this.lastVisitKey = process.env.REACT_APP_BROWSER_LAST_VISIT_KEY;
	}

	handleError(data){
		if(data && typeof(data)=='object' && data.status==401){
			const path = window.location.pathname;
			if(path.indexOf("/login")<0) sessionStorage.setItem(this.lastVisitKey, path);
			broadcast.postMessage('logout');
		}
		errorBC.postMessage(data.message);
		super.handleError(data);
	}
}


const api = new ResourceService();
export {ResourceService, api};