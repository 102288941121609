import React from 'react';
import ChainNetwork from './../states/chainNetwork';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const WalletContext = React.createContext();
const WalletWrapper = React.createContext();
const WalletProvider = ({children})=>{
	const chainNetwork = ChainNetwork();
	return (<WalletContext.Provider value={{chainNetwork}}>{children}</WalletContext.Provider>);
}

export {WalletProvider, WalletContext}; 