import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {BookContext} from './../../contexts/BookContext';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export default function BookInfo({next, back}) {
  const {book, onChange, submit, hasError} = React.useContext(BookContext);
  const [categories, setCategories] = React.useState('');
  const navigate = useNavigate();
	const createCategory = (e)=>{
    if (e.key !== 'Enter') return;
    onChange('categories', [...book.categories, categories]);
    setCategories('');
  }

  const deleteCategory = (index)=>{
    book.categories.splice(index, 1);
    onChange('categories', [...book.categories]);
  }

  const onSubmit = async()=>{
    console.log("onSubmit");
    await submit();
    next();
  }

  return (
    <Box
      sx={{
      	display:"flex",
      	flexDirection:"column",
      	justifyContent:"center",
      	alignItems: "center",
        padding: "20px"
      }}
    >
      <Typography variant="h6">Book Info</Typography>
      <br/>
      <TextField required label="Name" variant="outlined" style={{width: "100%"}} value={book.name} onChange={(e)=>onChange("name", e.target.value)}/>
      <TextField
          required
          multiline
          label="Description"
          value={book.description||""}
          onChange={(e)=>onChange("description", e.target.value)}
          maxRows={10}
          variant="outlined"
           style={{width: "100%"}}
        />
      <TextField required label="Author" variant="outlined" style={{width: "100%"}} value={book.author||""} onChange={(e)=>onChange("author", e.target.value)}/>
      <TextField required label="Price" type="number" variant="outlined" style={{width: "100%"}} value={book.price} onChange={(e)=>onChange("price", e.target.value)} InputProps={{readOnly: book.id?true:false}}/>

      <TextField sx={{marginBottom:'10px !important'}} label="Categories" variant="outlined" style={{width: "100%"}} value={categories} onChange={(e)=>setCategories(e.target.value)} onKeyDown={createCategory} />
      <div>Hit Enter to create multiple categories</div>
      <Stack direction="row" spacing={1} sx={{marginBottom: "20px", minHeight: 32}}>
        {book.categories.map((item, index)=>(<Chip key={index} label={item} onDelete={()=>deleteCategory(index)} />))}
      </Stack>
      <Box className="flex-center" sx={{minWidth: 360}}>
        <Button size="large" onClick={()=>navigate('/listing')}>Cancel</Button>
        <Button className="yellow"  disabled={hasError()} variant="contained" size="large" onClick={onSubmit} style={{minWidth:'100px', fontWeight: "300", lineHeight: "1.3"}}>{!book.id?'Create and Continue':'Save and Continue'}</Button>
      </Box>
    </Box>
  );
}