export default class APIService{
	constructor(serverUrl, storageType){
		this.apiUrl = serverUrl;
		this.path = "";
		this.totalCount = 0;
		if(!storageType) this.storage = "localStorage";
		else this.storage = storageType;
	}

	set serverUrl(url){ this.apiUrl = url};

	set tokenKey(key) { this.tk = key };

	set tokenValue(value) { 
		if(!window) return;
		window[this.storage].setItem(this.tk, value) 
	};

	get tokenValue() { 
		if(!window) return;
		return window[this.storage].getItem(this.tk) 
	};

	set tokenType(type) { this.storage = type };

	set resource(path) {this.path = path};

	deleteToken(){ 
		if(!window) return;
		window[this.storage].removeItem(this.tk) 
	};

	getPath(params, postfix=""){
		if(postfix && postfix[0]=="/") postfix = postfix.substring(1); 
		let path =  this.path + (postfix?`/${postfix}`:'');
		if(!params || typeof(params)!='object') return path; 
		return Object.entries(params).reduce((res, item)=>{
			return res.replace(`:${item[0]}`, item[1]);
		},path);
	}

	async post(postfix="", body, params){
		let res = await this.request({method: 'POST', path: this.getPath(params, postfix), body: body});
		return res;
	}

	async get(postfix="", body, params){
		let res = await this.request({method: 'GET', path: this.getPath(params, postfix), body: body});
		return res;
	}

	async put(postfix="", body, params){
		let res = await this.request({method: 'PUT', path: this.getPath(params, postfix), body: body});
		return res;
	}

	async delete(postfix="", body, params){
		let res = await this.request({method: 'DELETE', path: this.getPath(params, postfix), body: body});
		return res;
	}

	async request(params){
		this.totalCount = 0;
		let {method, path, body, headers} = params;
		body = this.formatBody(body);
		if(path[0] =='/') path = path.substring(1);
		let url = this.apiUrl + `/${path}`;
		
		if(!headers) headers = {'Content-Type': 'application/json'};
		const token = window?window[this.storage].getItem(this.tk):"";
		if(token) headers['Authorization'] = `Bearer ${token}`;
		let options = {method: method.toUpperCase(), headers}

		if(body && Object.entries(body).length){
		 	if(method =='GET') url = url + "?" + this.queryString(body);
		 	else options.body = JSON.stringify(body);
		}
		
		try{
			let response = await fetch(url, options);
			if(response.status && response.status==401){
			  this.deleteToken();
			  this.handleError(response);
			  return;
			}
			this.totalCount = parseInt(response.headers.get('total_count') || 0);
			response = await response.json();
			if(response.hasOwnProperty("error_message") || response.hasOwnProperty("error"))
				throw new Error(response["error"] || response["error_message"], response.status);
			
			return this.handleResponse(response); 
		}catch(err){
			console.log(err.message);
			err = this.handleError(err);
		};
	}

	formatBody(body){
		return body;
	}

	handleResponse(data){
		return data;
	}

	handleError(data){
		throw new Error(data.message);
	}

	queryString(body){
		return Object.entries(body).map(([key, value])=>{
			return `${key}=${encodeURIComponent(typeof(value)=="Object"?JSON.stringify(value):value)}`
		}).join("&");
	}
}