import React from "react";
import {AccountContext} from "./../../contexts/Account";
import {Box, Stack, Button, SvgIcon, List, Typography, ListItemButton, ListItemText, Icon} from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import { ReactComponent as StripeIcon } from './../../assets/stripe-icon.svg';

// import UpdatedStripeIcon from './../../assets/stripe-icon.png';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {listing} from "./../../services/Apis";
const Row = ({data, onEdit})=>{
	const navigate = useNavigate();
	return (
		<ListItemButton sx={{borderRadius:"20px", margin:"0 10px"}}> 
            <ListItemText primary={data.name}/>
            <Stack direction="row">
            		<Tooltip title="Preview"><IconButton onClick={()=>navigate(`/previews/${data._id}`, {state: data})}><SlideshowIcon/></IconButton></Tooltip>
            		<Tooltip title="Edit"><IconButton onClick={()=>onEdit(data)}><EditIcon/></IconButton></Tooltip>
            </Stack>
		</ListItemButton>
	)
}

const MyListing = ()=>{
	const {account} = React.useContext(AccountContext);
	const [data, setData] = React.useState([]);
	const [noListing, setNoListing] = React.useState(false);
	const navigate = useNavigate();
	const onEdit = val=>navigate(`/listing/${val._id}`, {state:{...val, id: val._id}});

	const getListing = async()=>{
		if(noListing) setNoListing(false);
		const res = await listing({params:{createdBy: account._id||account.id}}).catch(err=>[]);
		if(!res.length) setNoListing(true);
		setData(res);
	}

	React.useEffect(()=>{
		if(account)getListing();
	},[account]);
	return (
	   <Stack sx={{width: "100%", height:"100%"}} className="flex-center" direction="column"> 
		   	<Typography variant="h6">Book Listings</Typography>	
			  <List  dense sx={{ margin:5, width: '100%', maxWidth: 600, bgcolor: 'background.paper', height:400, overflow:"auto", position:"relative", border: "1px solid #d3d3d3"}}>
		        {data.map((item, index)=><Row data={item} key={data.id} onEdit={onEdit}/>)}
		        {noListing?(<Stack sx={{width: "100%", height:"100%", position:"absolute", top:0, left: 0}} className="flex-center" direction="column"><Typography sx={{color:"gray"}}>No Listing Found!</Typography></Stack>):(<></>)}
		    </List>
	   </Stack>
	)
}

export default function Listing(){
	const navigate = useNavigate();
	const {isLoggedIn, setLastVisitPage} = React.useContext(AccountContext);
	const init = ()=>{
		if(!isLoggedIn()){
			setLastVisitPage(); 
			navigate("/login");
			return;
		}
	}

	React.useEffect(()=>{
		init();
	}, []);

	return (
		<Stack sx={{width: "100%", height:"100%"}} className="flex-center" direction="column">
			<MyListing />
			<Stack sx={{width: "100%", height:"100%"}} className="flex-center" direction="row"> 
				<Button startIcon={<SvgIcon component={StripeIcon} inheritViewBox sx={{"&.MuiSvgIcon-root": {background: "#1976d2", padding: "2px"}}}/>} variant="contained" size="large" sx={{width:250,  borderRadius: "0", background: "#d9d9d9"}} onClick={()=>navigate("/listing/imports")}><span style={{color: "black"}}>Import</span></Button>
				<Button startIcon={<AutoStoriesIcon/>} variant="contained" size="large" sx={{width:250, color: "black", background: "yellow", borderRadius: "0"}} onClick={()=>navigate("/listing/new")}>New Listing</Button>
			</Stack>
		</Stack>
	);
}