import React from "react";
import {DashboardProvider} from "./../../contexts/DashboardContext";
import FeatureBooks from "./FeatureBooks";
import NewDrops from "./NewDrops";
import Intro from "./Intro";
import Footer from "./Footer";
import "./Dashboard.css";
export default function Dashboard(){
	return (
		<DashboardProvider>
			<FeatureBooks />
			<NewDrops />
			<Intro/>
			<Footer/>
		</DashboardProvider>

	)
} 