import React from 'react';
import {getBooks} from "./../services/Apis";
import { useNavigate } from 'react-router-dom';

const DashboardContext = React.createContext();
const DashboardProvider = ({children})=>{
	const [isLoading, setIsloading] = React.useState(true);
	const [books, setBooks] = React.useState([]);
	const navigate = useNavigate();

	const onItemSelect = (val)=>{
		if(typeof(val)=="string") return navigate(val);
		navigate(`/products/${val.id||val._id}`, {state:{...val}});
	}	

	React.useEffect(()=>{
		(async()=>{
			setIsloading(true);
			const res = await getBooks({}).catch(err=>[]);
			setBooks(res);
			setIsloading(false);
		})();
	}, []);

	return (<DashboardContext.Provider value={{books, isLoading, onItemSelect}}>{children}</DashboardContext.Provider>);
}

export {DashboardProvider, DashboardContext}; 