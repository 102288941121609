import React from 'react';
import {getBook, newListing, updateListing, uploadListingMedia, deleteListingMedia} from "./../services/Apis";

import Broadcast from './../services/Broadcast';

const broadcast = new Broadcast("z1-on-loading");

const defaultData = {
	name: "",
	description: "",
	price: "",
	author: "",
	covers: [],
	samples: [],
	categories: []
}
let hasChanged = false;

export default function BookForm(data){
	const [book, setBook] = React.useState(data || {...defaultData});
	const create = async()=>{
		broadcast.postMessage(true);
		const res = await newListing({body: book}).catch(err=>null);
		broadcast.postMessage(false);
		if(res && (res.id || res._id)) setBook({...book, id: (res.id || res._id)});
		return res;
	}

	const update = async()=>{
		broadcast.postMessage(true);
		const res = await updateListing({params:{id:book.id}, body:book}).catch(err=>null);
		broadcast.postMessage(false);
		return res;
	}

	const submit = async()=>{
		if(!hasChanged) return;
		if(book.id) await update();
		else await create();
		hasChanged = false;
	}	

	const onChange = (fieldName, value)=>{
		book[fieldName] = value;
		setBook({...book});
		hasChanged = true;
	}

	const init = (data)=>{
		setBook({...book, ...data});
	}

	const getProduct = async(id)=>{
		return await getBook({params: {id}}).catch(err=>null);
	}

	const uploadMedia = async(fieldName, fileObject)=>{
		const {content, type} = fileObject;
		if(!book.id) throw new Error("Book id is required");
		if(!Array.isArray(book[fieldName])) throw new Error("fieldName is not associated to a media field");
		broadcast.postMessage(true);
		const res = await uploadListingMedia({params: {id:book.id}, body:{fieldName, content, fileName:`${fieldName}_${Date.now()}.${type}`}}).catch(err=>null);
		broadcast.postMessage(false);
		if(!res) return;
		book[fieldName].push({url: res.url});
		setBook({...book});
	}

	//not done yet
	const deleteMedia = async(fieldName, index)=>{
		if(!book[fieldName] || !book[fieldName][index]) return;
		const val = book[fieldName].splice(index, 1);
		const res = await deleteListingMedia({params:{id:book.id}, body:{fieldName, fileName: val[0].url.split("/").pop()}}).catch(err=>null);
		setBook({...book});
	}

	const editMedia = async(fieldName, index, value="")=>{
		if(!book[fieldName] || !book[fieldName][index]) return;
		const val = book[fieldName][index];
		if(typeof(val)=="object"){
			val.caption = value;
			await update();
			setBook({...book});
		}
	}

	const hasError = ()=>{
		if(book.name && book.description && book.price && book.author) return false;
		return true;
	}	

	return {submit, onChange, uploadMedia, deleteMedia, editMedia, getProduct, book, init, hasError};
}