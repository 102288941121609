import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export default function Intro(){
	
	return( 
	  <>	
		<div className="parallax" style={{backgroundImage: "url('/banner/banner.svg')", height: "350px", padding: "0 40px"}}></div>
		<div className="intro-container"> 
		   <Box className="left">
				<Typography sx={{marginBottom:1.75}} variant="h4" data-aos="fade-zoom-in">What is BookForest</Typography>
				<Box>
					<Typography paragraph={true} variant="body1" sx={{color: "black"}} data-aos="fade-zoom-in">
					BookForest is the future of digital book publishing. Leveraging the power of blockchain, BookForest allows authors to publish their books as non-fungible tokens (NFTs), which provides numerous benefits to both authors and readers.
					</Typography>
					<Typography paragraph={true} variant="body1" sx={{color: "black"}} data-aos="fade-zoom-in">One significant advantage of publishing books as NFTs is that it creates a unique, immutable record of ownership on the blockchain. This ensures that the book is an original copy, and has not been altered in any way. Additionally, NFTs provide a secure and transparent way for authors to sell their works, as ownership and transaction history are recorded on the blockchain.
					</Typography>
					<Typography paragraph={true} variant="body1" sx={{color: "black"}} data-aos="fade-zoom-in">With BookForest, readers can have their digital books signed by the author, which provides a personal touch and adds value to the book. Moreover, readers can trade their digital books with other readers, allowing for a new level of interaction and engagement between readers.

					</Typography>
				</Box>
			</Box>
			<Box sx={{padding: "0px", textAlign: ["center", "center", "right"]}} className="right">
				<Typography paragraph={true} variant="body1" sx={{color: "black"}} data-aos="fade-zoom-in">
				Are you ready to take action and start selling your NFT book?<br/> Get in touch with us!
				</Typography>
				<a className="yellow" href="mailto:support@bookforest.io">CONTACT US</a>
			</Box>
		</div>
	</>)
}