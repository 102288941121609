import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useParams, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import {AccountContext} from './../../contexts/Account';
export default function StripeCallback(){
	const [searchParams, setSearchParams] = useSearchParams();
	const [message, setMessage] = React.useState("");
	const [loading, setLoading] = React.useState(true);
	const {stripeToken, account} = React.useContext(AccountContext);
	const navigate = useNavigate();
	const onCallback = async()=>{
		setLoading(true);
		const code = searchParams.get("code");
		const state = searchParams.get("state");
		if(!code || !state) return navigate("/login");
		const res = await stripeToken(state, code).catch(err=>setMessage(err.message));
		setLoading(false);
		if(!res) return;
		setMessage("Your Stripe has been setup successfully");
		// setTimeout(()=>navigate("/login"),3000);
	}

	React.useEffect(()=>{
		onCallback();
	},[]);

	return (
		<Box
	      component="form"
	      sx={{
	      	display:"flex",
	      	flexDirection:"column",
	      	justifyContent:"center",
	      	alignItems: "center",
	        padding: "20px"
	      }}
	    >
	       <p style={{color: "#000"}} className="Product-info-description">{message||""}</p>
			<>{loading?(<></>):(<a href={account?"/":"/login"} style={{textDecoration:'none', marginTop:20}}><Button>{account?"Main Screen": "Log In"}</Button></a>)}</>
	    </Box>
	);
}