import React from "react";
import {api} from "./../services/ResourceService";

export default function ImportBookState(){
	const [total, setTotal] = React.useState(100);
	const [data, setData] = React.useState([]);
	const [selected, setSelected] = React.useState({});
	const getProducts = async(params)=>{
		if(!params) params = {search:"",limit:100, offset:0};
		const res = await api.get(`/s/products?${params.search?(params.search+"&"):""}limit=${params.limit}&offset=${params.offset}`).catch(err=>[]);
		if(!params.offset) setTotal(api.totalCount);
		setData(res);
		return res;
	}

	const onCheckbox = (val)=>{
		if(selected[val.id]) delete selected[val.id];
		else selected[val.id] = val;
		setSelected({...selected});
	}

	const selectedProducts = ()=>Object.values(selected);

	const importProduct = (item)=>{
		item.sProductId = item.id;
		item.samples = item.trials||[];
		item.covers = item.covers || [];
		item.price = parseFloat(item.price);
		return api.post(`/products/import`, item);
	}

	return {importProduct, selectedProducts, selected, getProducts, total, data, onCheckbox}
}