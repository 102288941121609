import React from 'react';

let size = 0;
let dp = 0;
let forms = {};

export default function FormService(fields=[], data={}){
	const [formData, setFormData] = React.useState({error:{'__hasError':false}, data:{}}); 

	function init(fields=[], _data={}){
		data = _data;
		size = fields.length;
		dp =  Math.pow(2, size) -1;
		forms = fields.reduce((res, val, index)=>{ 
			res[val.id] = {...val, index}; 
			if(val.is_required) 
				dp = ((~(1<<index)) & dp) | (0<<index);
			return res;
		}, {});

		fields.forEach(({id, value})=>validate(id, _data[id]||value||"", true));
		setFormData({...formData}); 
	}

	const hasChanged = ()=>{
		for(let k in formData)
			if(formData[k]!=data[k]) return true;
		return false;
	}

	const format = (id, value)=>{
		if(!forms[id]) return;
		if(forms[id].format) return forms[id].format(value);
		return value;
	}

	const onChange = (id, value)=>{
		validate(id, value);
	}

	const validate = (id, value, noUpdate=false)=>{
		let error = "";
		if(forms[id]){
			setValid(id, true);
			if(!value){
				if(forms[id].is_required){
					setValid(id, false);
					if(!noUpdate) error = `Field is required`;
				}
			}
			else{
				if(forms[id].validation){
					try{
						forms[id].validation(value);	
					}catch(err){
						setValid(id, false);
						error = err.message;
					}	
				}
			}
		}
		formData.error[id] = error;
		formData.data[id] = value;
		formData.error['__hasError'] = hasError();
		if(noUpdate) return;
		setFormData({...formData}); 
	}

	const setValid = (id, val)=>{
		val = val?1:0;
		const index = forms[id].index;
		dp = ((~(1<<index)) & dp) | (val<<index);
	}

	const hasError = ()=>{
		return (dp + 1) != Math.pow(2, size);
	}

	React.useEffect(()=>{
		init(fields, data);
	}, []);

	return {data: formData.data, error: formData.error, onChange, hasChanged, init};
}
