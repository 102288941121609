import React from 'react';
import Keplr from "./../services/walletProviders/Keplr";
import {api} from "./../services/ResourceService";

const WALLETS = {Keplr};
let wallet = null;

export default function ChainNetwork(walletName="Keplr"){
	const [account, setAccount] = React.useState({});
	const setup = async(walletName="Keplr")=>{
		console.log("walletName::", walletName);
		if(!WALLETS[walletName]) throw new Error("Wallet is not supported");
		const name = walletName.toUpperCase() + "_";
		const chainIds = (process.env[`REACT_APP_${name}CHAIN_IDS`]||"").split(",");
		const chainRpcs = (process.env[`REACT_APP_${name}CHAIN_RPCS`]||"").split(",");
		const chainNames = (process.env[`REACT_APP_${name}CHAIN_NAMES`]||"").split(",");
		const chainTokens = (process.env[`REACT_APP_${name}CHAIN_TOKENS`]||"").split(",");
		const chainTokenNames = (process.env[`REACT_APP_${name}CHAIN_TOKEN_NAMES`]||"").split(",");
		const chainExplorers = (process.env[`REACT_APP_${name}CHAIN_EXPLORER_URLS`]||"").split(",");
		const chainIcons = (process.env[`REACT_APP_${name}CHAIN_ICONS`]||"").split(",");
		const networks = {};
		chainIds.forEach((id,index)=>{
			chainTokenNames[index] = chainTokenNames[index].trim();
			if(!chainTokenNames[index]) chainTokenNames[index] = chainTokens[index];
			networks[id] = {
				id,
				name: (chainNames[index]||"").trim(),
				token: (chainTokens[index]||"").trim(),
				tokenName: (chainTokenNames[index]||"").trim(),
				explorer: (chainExplorers[index]||"").trim(),
				rpc: (chainRpcs[index]||"").trim(),
				icon: (chainIcons[index]||"").trim()
			}
		});
		wallet = new WALLETS[walletName](chainIds, networks);
		await wallet.setup();
		setAccount({address: wallet.walletAddress()});
	}

	const onWalletChange = async(walletName)=>{
		await setup(walletName);
	}

	const request = async(method, params)=>{
		if(wallet && wallet[method]) return await wallet[method](params);
		api.handleError(new Error("Method doesn't exist"));
	}

	React.useEffect(()=>{
		if(walletName && window[walletName.toLowerCase()])
			setup(walletName);
	}, []);


	return {account, request, onWalletChange};

}