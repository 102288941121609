import React from "react";
import {Typography, Button, Box, Stack} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UploadImages from "./../UploadImages/UploadImages";
import MediaEditor from "./../MediaEditor/MediaEditor";
import {BookContext} from './../../contexts/BookContext';

export default function BookSample({next, back}){
  	const {uploadMedia, deleteMedia, editMedia, book, onChange, submit} = React.useContext(BookContext);
  	const [option, setOption] = React.useState(0);

  	const onSave = async(val)=>{
  		onChange('samples', val);
  		await submit();
  	}

	return <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems: "center", padding: "20px"}}>
				<Typography variant="h6">Book Sample Images</Typography>
      			<br/>
      			<Stack direction="row" spacing={2} sx={{marginBottom:5}}>
      				<Button size="small" variant={option?"outlined":"contained"} startIcon={<UploadFileIcon/>} onClick={()=>setOption(0)}>Upload Medias</Button>
      				<Button size="small" variant={option?"contained":"outlined"} startIcon={<DescriptionIcon/>} onClick={()=>setOption(1)}>Open Editor</Button>
      			</Stack>
      			{
      				(!option)?(
      					<UploadImages 
							onUpload = {val=>uploadMedia('samples', val)} 
							onDelete = {val=>deleteMedia('samples', val)} 
							onUpdate = {(index, val)=>editMedia('samples', index, val)} 
							files = {book.samples} 
						/>
					):(
						<MediaEditor data={book.samples} onSave={onSave}/>
					)
      			}
			    <Box className="flex-center" sx={{minWidth: 360, marginTop:10}}>
		      		<Button  size="large" onClick={back} style={{minWidth:'100px'}}>Back</Button>
		      		<Button className="yellow" variant="contained" size="large" onClick={next} style={{minWidth:'100px', fontWeight: "300", lineHeight: "1.3"}}>Done</Button>
		      	</Box>
	      	
		   </Box>
}