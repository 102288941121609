import React from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {api} from "./../../services/ResourceService";
import Box from '@mui/material/Box';
import { Hidden, Button } from '@mui/material'
import TransactionSuccess from "./../TransactionSuccess";
import { WalletContext } from "../../contexts/Wallet";
import Broadcast from './../../services/Broadcast';
const broadcast = new Broadcast("z1-on-loading");

export default function SessionCallback(){
    const wallet = React.useContext(WalletContext);
	const navigate = useNavigate();
	const {productId} = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [status, setStatus] = React.useState('');
	const [message, setMessage] = React.useState('');

	const sender = "tpham@z1bc.com";
	React.useEffect(()=>{
		(async()=>{
			if(searchParams.get("status")=="cancel"){
				navigate("/");
				api.handleError(new Error("Transaction has been canceled"));
			}
			else{
				broadcast.postMessage(true);
				const res = await api.post(`/products/${productId}/transactions`, {sessionId: searchParams.get("session"), channel: searchParams.get("channel")}).catch(err=>({status:"error", message:err.message}));
				if(res && res.status) setStatus(res.status);
				if(res && res.message) setMessage(res.message);
				broadcast.postMessage(false);

			}
		})();
	},[]);

	return (<Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent:"center", padding: '20px'}}>
			  <>
				  {
				  	(status === "success")?(<TransactionSuccess/>):
				    (status === "pending")?(<h2 className="Product-info-subtitle">To Receive NFT please connect your wallet</h2>):
				    (status === "error")?(<h2 className={"Product-info-subtitle"} style={{color:"red"}}>Transaction Creation Failed!</h2>):
				    (<></>)	
				  }
			  </>
			  <>
			  {
				  status !== "pending"? <p style={{color: "#000"}} className="Product-info-description">{message||""}</p>: <></>
			  }
			  </>
			  {
				status === "pending"? 
				<div>
					<h4>Here are the steps: </h4>
					<ul style={{listStyleType: "none"}}>
						<li style={{marginBottom: "25px"}}>1. Check your email (it wil be from {sender})</li>
						<li style={{marginBottom: "25px"}}>2. Click "Connect Wallet" from the email <div>Example: <br/><img style={{maxWidth: "100%"}} src="/steps_to_connect_wallet.png" alt="Steps explanation"/></div></li>
						<li>3. Your BookNFT will be signed and delivered to your wallet within 24 hours.</li>
					</ul>
				</div>
				:
				<></>
			  }
			  <>{status&&<a href="/" style={{textDecoration:'none', marginTop:20}}><Button>Back to the main screen</Button></a>}</>
			</Box>)
}