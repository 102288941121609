import React from "react";
import Box from '@mui/material/Box';
import {Tooltip, IconButton, TablePagination} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { MaterialReactTable } from 'material-react-table';

import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

//https://www.material-react-table.com/docs/examples/basic




export default function TableTemplate({tableAttributes=[], apiRequest, enableRowActions, rowActions}){
  	let [items, setItems] = React.useState([]);
  	let [pagination, setPagination] = React.useState({total:0, offset:0, limit: 10});
  	let [loading, setLoading] = React.useState(false);

  	const getItems = async()=>{
  		setLoading(true);
  		const res = await apiRequest({...pagination, offset: pagination.offset*pagination.limit}).catch(err=>{console.log(err); return {};});
  		setPagination({...pagination, total: res.total||0});
  		setItems(res.items||[]);
  		setLoading(false);
  	}

  	const init =()=>{
  		setLoading(true);
  		setTimeout(function() {
	  		getItems();
  		}, 1000);
  	}

	const handleExportData = () => {

		const exportData = items.map(function(obj) {
			var selectedObj = {};
		
			tableAttributes.forEach(function(attribute) {
			  selectedObj[attribute.accessorKey] = obj[attribute.accessorKey];
			});
		
			return selectedObj;
		  });

		csvExporter.generateCsv(exportData);
	  };

  	const handleChangeRowsPerPage = (event) => {
	    pagination.limit = parseInt(event.target.value, 10);
	    pagination.offset = 0;
	    getItems();
	};

	const handleChangePage = (event, offset)=>{
		pagination.offset = offset;
		getItems();
	}
	  

	React.useEffect(()=>{
		init();
	},[]);


	const csvOptions = {
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		useBom: true,
		useKeysAsHeaders: false,
		headers: tableAttributes.map((c) => c.header),
	  };
	  
	const csvExporter = new ExportToCsv(csvOptions);	

	const callback = getItems;

	return (
		    <Box sx={{ height: "calc(100vh - 330px)"}}>
				<MaterialReactTable 
					enablePagination={false}
					columns={tableAttributes} 
					data={items} 
					enableRowActions={enableRowActions} 
					renderRowActions={ ({row}) => rowActions({row, callback})} 
					positionActionsColumn='last'
					renderTopToolbarCustomActions={({ table }) => (
						<Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'  }}>
							<IconButton  onClick={handleExportData}>
								<Tooltip title="Export to CSV">
									<FileDownloadIcon />
								</Tooltip>
							</IconButton>
						</Box>
					)}

				/>
				<TablePagination
	      			component="div"
	          		onPageChange={handleChangePage}
	          		onRowsPerPageChange={handleChangeRowsPerPage}
	          		count={pagination.total}
	          		rowsPerPage={pagination.limit}
	          		page={pagination.offset}>

				</TablePagination>

	        </Box>
	)
}