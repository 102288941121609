import React from "react";
import Box from '@mui/material/Box';
import {WalletContext} from "./../../contexts/Wallet";
import {api} from "./../../services/ResourceService";
import {getBook} from "./../../services/Apis";
import Carousel from 'react-material-ui-carousel'
import useMediaQuery from '@mui/material/useMediaQuery';
import BackButton from "../BackButton/BackButton";
import { Paper, LinearProgress, Button, Hidden } from '@mui/material'
import QuestionMark from "@mui/icons-material/QuestionMarkOutlined"
import LoadingButton from "../LoadingButton/LoadingButton";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';

import Broadcast from './../../services/Broadcast';

const broadcast = new Broadcast("z1-on-loading");

export default function Product(){
  const wallet = React.useContext(WalletContext);
  const [onSubmit, setOnSubmit] = React.useState(false);
  const matches = useMediaQuery('(min-width: 997px)');

  const {productId, channelId} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [product, setProduct] = React.useState(location.state);

	const onClick = async()=>{
    let res = null;
    // if(!wallet.chainNetwork.account.address) return api.handleError(new Error("Wallet is missing"));
    if(onSubmit) return;
    setOnSubmit(true);
    broadcast.postMessage(true);
    res = await api.post(`/products/${product.id||product._id}/checkout`, {clientUrl: window.location.origin, walletAddress: wallet.chainNetwork.account.address, channel: channelId}).catch(err=>null);
    broadcast.postMessage(false);
	  setOnSubmit(false);
    if(res && res.url) window.location.href = res.url;
  }

  const getProduct = async()=>{
    broadcast.postMessage(true);
    const res = await getBook({params: {id: productId}}).catch(err=>null);
    broadcast.postMessage(false);
    setProduct(res);
  }

const Item = (props) => 
{ 
  const {item} = props;
    return (
        <Paper sx={{backgroundColor: "#d3d3d3", position: "relative", height: "100%"}}>
           <Box sx={{padding: "50px 0", width: "100%", height: "100%"}}>
              <div style={{width: "75%", margin: "0 auto"}}> 
                <picture>
                  <img className="custom-carousel-img" src={item.url ||""} alt="cover" width="100%"/>
                </picture>
              </div>
              <Box sx={{ textAlign: "center", width: "100%"}}>
                <Box sx={{"& > :first-child": {
                fontSize: ["17px", "17px", "24px"]
              }}} dangerouslySetInnerHTML={{ __html: item.caption||"" }} ></Box>
              </Box>
           </Box>
        </Paper>
    )
}

const Loader = () => {
  return (
    <Box sx={{position: "absolute", left: "50%", top: "50%", textAlign: "center"}}>
     <LinearProgress/>
    </Box>
  )
}

const ProductInfo = () => {
  return <Box sx={{width: ["auto", "auto", "50%"], padding: ["30px", "30px", "20px 100px 20px 100px"], order: ["2", "2" ,"1"]}}>
           <Box sx={{position: "absolute", top: ["-50px", "-50px", "0"]}}>
             <BackButton onCallback={null}/>
           </Box>
          <h1 className="Product-info-title">{product.name || "Name of the book"} <span className="Product-info-subtitle special">NFT</span></h1>
        
          <span className="Product-info-subtitle">by {product.author ||""}</span>

          <p className="Product-info-description">{product.description || "Description"}</p>
        <Box sx={{display: "flex", flexDirection: ["column", "column", "row"], width: ["100%", "100%", "auto"], alignItems: "center", marginTop: ["50px", "50px", "20px"]}}>
          <Hidden smDown>
              <Button  sx={{background: "yellow", color: "black", padding: ["5px 15px", "5px 55px"]}} variant="outlined" onClick={onClick}>BUY NOW</Button>
          <span className="Product-info-price">${product.price ||"N/A"}</span>
          </Hidden>

          <Hidden smUp>
          <span className="Product-info-price">${product.price ||"N/A"}</span>
            <Button  sx={{background: "yellow", color: "black", padding: ["5px 15px", "5px 55px"]}} variant="outlined" onClick={onClick}>BUY NOW</Button>
          </Hidden>
        </Box>
    </Box>
}

const ProductGallery = () => {
  return <Box sx={{width: ["100%", "100%", "50%"], order: ["1", "1" ,"2"]}}>
    {<Carousel height={matches ? "670px": "350px"} className="custom-carousel" autoPlay={true} indicators={false} navButtonsAlwaysVisible={true}>
      {
       (product.covers||[]).length?(product.covers.map((img, i) => <Item item={img} key={i}></Item>)):
       (<Box className="flex-center" sx={{width:"100%", height:"100%", height:"100%"}}><Skeleton variant="rectangular" width="300px" height="400px" className="book-container feature-book-skeleton"></Skeleton></Box>)
      }
    </Carousel>}
</Box>
}
  
  React.useEffect(()=>{
    if(!product) getProduct();
  }, []);
	return (
    <>
    <Box sx={{width: "100%", height: "100%"}}>
      {
        !product ? <Loader/>: 
        <>
        <Box sx={{position: "relative", width: "100%", display: "flex", flexDirection: ["column", "column", "row"], alignItems: ["start", "start", "center"]}}>
          <ProductInfo/>
          <ProductGallery/>
        </Box>

        <Box sx={{display: "flex",flexDirection: ["column", "column", "row"], width: "100%"}}>
          <Box sx={{width: ["auto", "50%", "50%"], marginTop: ["0px", "0px","-50px"], padding: ["30px", "30px", "0px 100px"]}}>
          </Box>
          <Box sx={{width: ["auto", "50%", "50%"], padding: "50px 30px", textAlign: ["center", "center", "start"], position: "relative", zIndex: "55"}}>
            <Box>
              <p className="Product-info-subtitle">Check first few pages</p>
              <Box sx={{display: "flex", flexDirection: ["column", "column", "row"], alignItems: ["space-between", "space-between", "start"], justifyContent: ["start", "start", "space-between"]}}>
                {
                  (product.samples ||[]).map((trial, index) => (
                    <Box className={`cover-book-wrap-${index + 1}`} key={index} sx={{width: ["100%", "100%", "33%"], textAlign: "center"}}><img className="cover-book" style={{marginBottom: "20px"}} width="100%" src={trial.url} alt="book pages"/></Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Box>
        </>
      }
    </Box>
    </>
	);
}