import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Skeleton } from "@mui/material";

export default function Footer(){
	
	return <div className="footer">
		<Box>
			<Typography sx={{fontWeight: "700"}} variant="h5">About</Typography>
			<Typography sx={{color: "#959595", width: "70%"}} variant="body1">An NFT book platform is 
provided for authors to 
showcase, sell, and trade their books.</Typography>
		</Box>
		<Box>
			<Typography sx={{fontWeight: "700", margin: "10px 0"}} variant="h5">Contact Info</Typography>
			<Box sx={{display: "grid", gap: "20px"}}>
				<Typography variant="body1"><a style={{color: "black", textDecoration: "none", fontWeight: "700"}} href="mailto: support@bookforest.io">support@bookforest.io</a></Typography>
			</Box>
		</Box>
		<Box>
			<Typography sx={{fontWeight: "700"}} variant="h5">Useful links</Typography>
			<Typography sx={{fontWeight: "700"}} variant="body1"><a style={{color: "black", textDecoration: "none"}} href="#">Main page</a></Typography>
			<Typography sx={{fontWeight: "700"}} variant="body1"><a style={{color: "black", textDecoration: "none"}} href="https://www.keplr.app/download">How to connect wallet</a></Typography>
			
		</Box>
	</div>
}