import React from "react";
import ScreenProgress from "./components/ScreenProgress";
import SystemError from "./components/SystemError";
import PageRouter from "./PageRouter";
// import Header from "./components/Header/Header";
// import Dashboard from "./components/dashboard/Dashboard";
// import Products from "./components/products/Products";
// import ListingDetail from "./components/ListingDetail/ListingDetail";
// import Redeem from "./components/redeem/Redeem";
// import SignUp from "./components/Login/SignUp";
// import Login from "./components/Login/Login";
// import StripeListing from "./components/StripeListing/StripeListing";

// import StripeCallback from "./components/StripeCallback/StripeCallback";
// import SessionCallback from "./components/sessionCallback/SessionCallback";
// import Transactions from "./components/Transactions/Transactions";
// import AccountPortal from "./components/accountPortal/AccountPortal";

import WebFont from "webfontloader";
import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

import './App.css';

import { WalletProvider } from "./contexts/Wallet";
import { AccountProvider } from "./contexts/Account";

import Product from "./components/products/Product";
import Listing from "./components/Listing/Listing";


function App() {
React.useEffect(() => {
  WebFont.load({
    google: {
      families: ["Jaldi"]
    }
  });
  AOS.init();
}, []);


  return (
    <WalletProvider>
      <AccountProvider>
        <SystemError/>
        <ScreenProgress/>
        <PageRouter />
      </AccountProvider>
    </WalletProvider>
  );
}



export default App;
