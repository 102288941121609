import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';

import Broadcast from './../services/Broadcast';

const broadcast = new Broadcast("z1-system-error");

const MESSAGE_ALIVE = 100;

export default function SystemError(){
	const [message, setMessage] = React.useState("");
	
	React.useEffect(()=>{
		broadcast.onMessage(data=> {
			setMessage(data);
		});
	},[]);

	const onClose = ()=>{
		setMessage("");
	}
	const transition = (props)=>{
	  	return <Slide direction="up"  {...props} />;
	}

	return (
		<Stack spacing={2} sx={{ width: '100%' }}>
			<Snackbar open={message?true:false} autoHideDuration={MESSAGE_ALIVE} TransitionComponent={transition} anchorOrigin={{vertical:'bottom', horizontal:'right'}}>
	        	<MuiAlert variant="filled" onClose={onClose} severity="error" sx={{ width: '100%' }}>{message}</MuiAlert>
	      	</Snackbar>
      	</Stack>
	)
}