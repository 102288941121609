import React from "react";
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import DragDropFile from "./../DragDropFile/DragDropFile";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';

import "./UploadImages.css";


const fileToBase64 = (fileContent, encode)=>{
	console.log("fileToBase64");
	const type = fileContent.name.split(".").pop().toLowerCase();
	const reader = new FileReader();
    return new Promise((res, err)=>{
    	reader.readAsDataURL(fileContent);
    	reader.onload = ()=>res({content: reader.result, type});
	   reader.onerror = error=>res(null);
    });
}

export default function UploadImages({files=[], onUpload, onDelete, onUpdate}){
	const [displayDescription, setDisplayDescription] = React.useState((files[0]||{}).caption || "");
	const [showTextArea, setShowTextArea] = React.useState(false);
	const [displayIndex, setDisplayIndex] = React.useState(0);
	const [showThumbnails, setShowThumbnails] = React.useState(false);
	 const inputRef = React.useRef(null);

	const onMediaIndexChange = (index)=>{
		setDisplayDescription((files[index]||{}).caption ||"");
	}

	const onFile = async(val)=>{
		const len = files.length;
		const content = await fileToBase64(val[0]);
		if(!content) return;
		await onUpload(content);
		setDisplayIndex(len);
		onMediaIndexChange(len);
	}

	const onBack = ()=>{
		if(showTextArea) return;
		const index = displayIndex?(displayIndex - 1):(files.length-1);
		setDisplayIndex(index);
		onMediaIndexChange(index);
	}

	const onNext = ()=>{
		if(showTextArea) return;
		const index = (displayIndex==files.length-1) ?0: (displayIndex+1);
		setDisplayIndex(index);	
		onMediaIndexChange(index);
	}

	const onStartEdit = ()=>{
		setShowTextArea(!showTextArea);
		setTimeout(()=>inputRef.current.focus(), 500);
	}

	const onSave = ()=>{
		onUpdate(displayIndex, displayDescription);
		setShowTextArea(!showTextArea);
	}

	const onCaptionKeyDown = (e)=>{
		if (e.key !== 'Enter') return;
		onSave();
	}

	const onDeleteMedia = async()=>{
		const index = displayIndex?(displayIndex-1):displayIndex;
		await onDelete(displayIndex);
		setDisplayIndex(index);
		onMediaIndexChange(index);
	}

	return (
		<DragDropFile onFile={onFile}>
			<Box>
				{	
				   files.length?(
					<div style={{width:"100%"}}>
						<div style={{width:"100%"}} className="gallery-modal">
							<div className="gallery-modal-image" style={{minHeight:"200px"}}>
								{
								 	files.map((item, index)=><img key={index} className={`display-image display-image-${index}`} src={item.url} style={{height:"100%", objectFit: "contain", opacity: (displayIndex==index)?1:0, transform: `scale(${displayIndex==index?1:2})`}}/>)
								}
							</div>
							<div className="row-flex gallery-edit-icons">
								<>
								{
									showTextArea?(
										<IconButton size="small" variant="outlined" onClick={()=>onSave()}><SaveIcon fontSize="small" /></IconButton>
									):(
										<>
											<IconButton size="small" variant="outlined" onClick={onStartEdit}><EditIcon fontSize="small" /></IconButton>
											<IconButton size="small" variant="outlined" onClick={()=>onDeleteMedia()}><ClearIcon fontSize="small" /></IconButton>
										</>
									)
								}			
								</>
							</div>
							<div className="gallery-left-arrow" onClick={onBack}>
								<ArrowBackIosIcon />
							</div>

							<div className="gallery-right-arrow" onClick={onNext}>
								<ArrowForwardIosIcon />
							</div>
							<div id="notShowTextArea" className="gallery-modal-description gallery-static-caption" style={{visibility: showTextArea?"hidden":"visible", textAlign:"center"}}>
								{displayDescription?(<div style={{padding: "10px"}} dangerouslySetInnerHTML={{ __html: displayDescription }} />):(<></>)}
							</div>   
							<div style={{visibility: showTextArea?"visible":"hidden"}} id="showTextArea" className="gallery-description-content">
								<textarea ref={inputRef} id="captionId"  style={{width:"100%", height:"100%", color:"white", position: "relative"}} value={displayDescription} onChange={e=>setDisplayDescription(e.target.value)}/>
							</div>  
						</div>         
						<div className="gallery-controller">
							<div className="gallery-panel row-flex">
								<IconButton fontSize="small" onClick={onBack}><ArrowBackIosIcon fontSize="small"/></IconButton>
								<IconButton fontSize="small" onClick={onNext}><ArrowForwardIosIcon fontSize="small"/></IconButton>
								<IconButton fontSize="small" onClick={()=>setShowThumbnails(!showThumbnails)}><ViewModuleIcon fontSize="small"/></IconButton>
								<div className="auto-flex"></div>
								<div className="gallery-panel-icon gallery-pagination-container" style={{color:"rgb(0, 0,0, 0.65) !important", fill:"rgb(0, 0,0, 0.65) !important", padding:"5px 10px !important"}}><span className="gallery-pagination">{(displayIndex + 1)}</span>|<span>{files.length}</span></div>
							</div>
							<div className={`gallery-thumbnails ${showThumbnails?'show_thumnails':''}`}>
								{files.map((item, index)=>(
									<div className={`thumbnail-container ${((displayIndex==index)?'thumbnail-selected':'')}`} onClick={()=>setDisplayIndex(index)}>
										<div className="thumbnail-overlay"></div>
										<img src={item.url} style={{height:"100%", objectFit: "contain", overflow:"hidden"}} />
									</div>
								))}
							</div>
						</div>
					</div>):<div style={{minHeight:"200px"}}></div>
				}	
			</Box>
		</DragDropFile>
	)
}