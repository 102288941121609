import React from "react";
import {WalletContext} from "./../../contexts/Wallet";
import {api} from "./../../services/ResourceService";
import { useSearchParams } from "react-router-dom";
import TransactionSuccess from "./../TransactionSuccess";
import { Box, Button } from '@mui/material';
import QuestionMark from "@mui/icons-material/QuestionMarkOutlined"
import Broadcast from './../../services/Broadcast';

const broadcast = new Broadcast("z1-on-loading");

export default function Redeem(){
  	const wallet = React.useContext(WalletContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const [status, setStatus] = React.useState("");
	const [message, setMessage] = React.useState("");
	const validateRedeemCode = async ()=>{
		const walletAddress = wallet.chainNetwork.account.address;
		if(!walletAddress) return;
		const code = searchParams.get('code');
		const token = searchParams.get('token');
		broadcast.postMessage(true);
		const res = await api.post(`/products/redeem`, {code, token, walletAddress}).catch(err=>({status:"error", message:err.message}));
		if(res && res.status) setStatus(res.status);
		if(res && res.message) setMessage(res.message);

		broadcast.postMessage(false);
	} 

	React.useEffect(()=>{
		validateRedeemCode();
	}, [wallet.chainNetwork.account.address]);

	return (<Box sx={{width: "100%", height: "90%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
				<>
				  {
				  	(status === "success")? (<TransactionSuccess/>):
				    (status === "pending")? (<h2 className={"Product-info-subtitle"} style={{color:"orange"}}>Your Transaction is Pending!</h2>):
				    (status === "error")? (<h2 className={"Product-info-subtitle"} style={{color:"red"}}>Transaction Creation Failed!</h2>):
				    (<></>)	
				  }
			  </>
			  
			  <p style={{color: "#000"}} className="Product-info-description">{message||""}</p>

			  <>{status&&<a href="/" style={{textDecoration:'none', marginTop:20}}><Button>Back to the main screen</Button></a>}</>
			  
			  <>
			  	{wallet.chainNetwork.account.address?(<></>):
					(<>
						<Button className="buy-now" sx={{padding: ["5px, 15px", "5px 45px"], background: "#d9d9d9", color: "black"}} variant="outlined" onClick={()=>wallet.chainNetwork.onWalletChange()}>Connect Your Wallet and Continue</Button>
						<Box sx={{marginTop:"20px"}}>
			                <a style={{display: "flex", alignItems: "center", textDecoration: "none", color: "black"}} href="https://help.keplr.app/articles/installation-guide-for-keplr-extension-for-beginners" className="Product-info-subtitle"><QuestionMark style={{marginRight: "10px"}}/> How to install Keplr wallet</a>
			            </Box> 
					</>)
				}
			 </>
			</Box>);
;
}
