import React from "react";
import "./StripeListing.css";
import Box from '@mui/material/Box';

import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { useNavigate } from "react-router-dom";

import ImportBooks from "./ImportBooks";
import StripeBooks from "./StripeBooks";
import {StripeListingProvider} from "./../../contexts/StripeListingContext";
const Components = [StripeBooks, ImportBooks];

export default function StripeListing() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const maxSteps = Components.length;

  const handleNext = () => {
  	if(activeStep==maxSteps-1) return navigate("/");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const renderSection = (index) => {
  	if(index!=activeStep) return (<></>);
    const Component = Components[activeStep];
    return <Component next={handleNext} back={handleBack} />;
  };

  return (
  	<StripeListingProvider>
	  	<Box sx={{
	      	width: "100vw",
	      	height: "80vh",
	      }}>
		    <Box sx={{display:"flex", flexDirection:"column",
	      	justifyContent:"center",
	      	alignItems: "center"}}>
		      <SwipeableViews
		        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
		        index={activeStep}
		        onChangeIndex={handleStepChange}
		        style={{width:"100%", minHeight:"400px"}}
		      >
		        {Components.map((section, index)=>renderSection(index))}
		      </SwipeableViews>
		    </Box>
		</Box>
	</StripeListingProvider>
  );
}