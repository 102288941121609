import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BookInfo from "./BookInfo";
import BookSample from "./BookSample";
import BookCover from "./BookCover";
import { BookProvider, BookContext } from "./../../contexts/BookContext";

const Components = [BookInfo, BookCover, BookSample];
export default function Container (){
	return <BookProvider><ListingDetail/></BookProvider>
}

function ListingDetail() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const {productId} = useParams();
  const location = useLocation();  
  const {init, getProduct} = React.useContext(BookContext);
  const maxSteps = Components.length;

  const handleNext = () => {
  	if(activeStep==Components.length-1) return navigate("/");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleSubmit = ()=>{

  }

  const renderSection = (index) => {
  	if(index!=activeStep) return (<></>);
    const Component = Components[activeStep];
    return <Component next={handleNext} back={handleBack} />;
  };

  const setup = async(productId, product)=>{
    if(!product) product = await getProduct(productId);
    if(product) init({...product, id: productId});
  }

  React.useEffect(()=>{
    if(productId){
      const product = location.state;
      setup(productId, product);
      window.history.pushState(null, null); 
    }
  }, []);

  return (
  	<Box sx={{
      	width: "100vw",
      	height: "80vh",
      }}>
	    <Box sx={{display:"flex", flexDirection:"column",
      	justifyContent:"center",
      	alignItems: "center"}}>
	      <SwipeableViews
	        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
	        index={activeStep}
	        onChangeIndex={handleStepChange}
	        style={{width:"100%", minHeight:"400px"}}
	      >
	        {Components.map((section, index)=>renderSection(index))}
	      </SwipeableViews>
	    </Box>
	</Box>
  );
}

