import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import BookIcon from '@mui/icons-material/Book';
import {AccountContext} from "./../../contexts/Account";
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";

export default function ProfileMenu() {
  const navigate = useNavigate();
  const {account, logout} = React.useContext(AccountContext);

  return (
      <MenuList sx={{ width: 320, maxWidth: '100%' }} dense>
        <Stack sx={{minHeight:100, marginBottom:"20px"}} direction="column" spacing={2} justifyContent="center" alignItems="center">
            <Avatar sx={{ bgcolor: deepOrange[500], width: 70, height: 70 }}>{account.firstName[0].toUpperCase() + account.lastName[0].toUpperCase()}</Avatar>
            <Typography variant="h6" sx={{marginTop:"0 !important"}}>{account.firstName + " " + account.lastName}</Typography>
            <Typography variant="body2" sx={{marginTop:"0 !important", color:"rgba(0,0,0,0.5)"}}>{account.email}</Typography>
        </Stack>
        <MenuItem onClick={()=>navigate("/listing")}>
          <ListItemIcon>
            <BookIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Book Listing</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={()=>navigate("/profile")}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>My Account</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText onClick={logout}>Logout</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>App Version</ListItemText>
          <Typography variant="body2" color="text.secondary">{"V"+process.env.REACT_APP_VERSION}</Typography>
        </MenuItem>
      </MenuList>
  );
}
