import React from "react";
import {Tooltip, Button} from '@mui/material';
import TableTemplate from "./TableTemplate";


import {getCount, getTransactions, updateTransaction} from "./../../services/Apis";
import {parseDate, parsePrice} from "../../helper/Parser";
import { AssuredWorkloadOutlined } from "@mui/icons-material";
const TRANSACTION_STATUS = "transaction::pending";
const tableAttributes = [
	{accessorKey: "sProductId", header: "Product Id"},
	{accessorKey: "productName", header: "Product Name"},
	{accessorKey: "price", header: "Price", Cell: ({cell})=> parsePrice(cell.getValue())},
	{accessorKey:"channel", header:"Channel"},
	{accessorKey: "createdDate", header: "Date", Cell:({cell}) => parseDate(cell.getValue())},
	{accessorKey: "name", header: "Buyer Name"},
	{accessorKey: "email", header: "Buyer Email"},
	{accessorKey: "connectWalletUrl", style: {color:"#1976d2 !important"}, header: "Redeem Url", Cell:({cell}) => WalletUrlCell(cell) },
];


function ActionButton({item, callback}){
	const markAsSent=async()=>{
		console.log("item::", item);
		// await updateTransaction();
		callback();
	}
	return (<Button sx={{backgroundColor: "yellow", color: "black"}} variant="contained" onClick={markAsSent}>Mark as Send</Button>);
}

function WalletUrlCell(cell){
	const value = cell.getValue();
	const [copyLabel, setCopyLabel] = React.useState("Copy Redeem Ur");
	const onCopy = ()=>{
		navigator.clipboard.writeText(value||"");
		setCopyLabel("Copied");
		setTimeout(function() {
			setCopyLabel("Copy Redeem Ur");
		}, 3000);
	}
	return <Tooltip title={copyLabel}><a href="#" onClick={()=>onCopy()} style={{cursor:"pointer", textDecoration: "none"}}>{value?"Click to Copy":""}</a></Tooltip>
}


export default function PendingTransaction(){
	const apiRequest = async(pagination)=>{
	 	const items = await getTransactions({params:{...pagination, status:TRANSACTION_STATUS}}).catch(()=>[]);
	 	const total = getCount();
	 	return {items, total};
	}
	return (<TableTemplate tableAttributes={tableAttributes} apiRequest={apiRequest}/>);
}

