import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {DashboardContext} from "./../../contexts/DashboardContext";
import Skeleton from '@mui/material/Skeleton';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
// const AutoPlaySwipeableViews = (SwipeableViews);


export default function FeatureBooks(){
	const {books, isLoading, onItemSelect} = React.useContext(DashboardContext);
	return(
		<Box className="feature-book">
			{isLoading?<Loading/>:(<Carousel items={books} onItemSelect={onItemSelect}/>)}
		</Box>)
}

function Loading(){
	return (
	<Box className="flex-row feature-book-container" sx={{height:"100%"}}>
		<Box className="feature-book-cover flex-row" data-aos="zoom-out">
			<Skeleton variant="rectangular" width="300px" height="100%" className="book-container feature-book-skeleton">
	        </Skeleton>
		</Box>
		<Box className="feature-book-description flex-column" data-aos="zoom-out">
			 <Skeleton width="300px" height="45px" className="feature-book-skeleton" sx={{marginBottom:1.75}}/>
			 <Skeleton width="200px" height="24px" className="feature-book-skeleton" sx={{marginBottom:1.75}}/>
			 <Skeleton width="400px" height="16px" className="feature-book-skeleton"/>
			 <Skeleton width="400px" height="16px" className="feature-book-skeleton"/>
			 <Skeleton width="400px" height="16px" className="feature-book-skeleton"/>
			 <Skeleton width="400px" height="16px" className="feature-book-skeleton"/>
			 <Skeleton width="400px" height="16px" className="feature-book-skeleton"/>

		</Box>
	</Box>)
}

function Item({item, onItemSelect}){
	return (
	<Box className="flex-row feature-book-container" sx={{height:"100%"}}>
		<Box className="feature-book-cover flex-row" data-aos="zoom-out">
			{item.covers.length?
				(<img src={(item.covers[0]||{}).url} className="book-container" onClick={()=>onItemSelect(item)}/>):
				(<Skeleton onClick={()=>onItemSelect(item)} variant="rectangular" width="300px" height="100%" className="book-container feature-book-skeleton"></Skeleton>)}
		</Box>
		<Box className="feature-book-description flex-column" data-aos="zoom-out">
			 <Typography sx={{marginBottom:1, fontWeight:600}} variant="h4">{item.name}</Typography>
			 <Typography sx={{marginBottom:1}} variant="h6">{item.author}</Typography>
			 <Typography sx={{marginBottom:1}} variant="body2" paragraph={true} sx={{fontWeight:300}}>{item.description}</Typography>
		</Box>
	</Box>)
}

function Carousel({items, onItemSelect}){
	const theme = useTheme();
  	const [activeStep, setActiveStep] = React.useState(0);
  	const maxSteps = items.length;
	const handleStepChange = (step: number) => {
	  setActiveStep(step);
	};

	return (
		<Box sx={{height:"100%", width:"100%"}}>
			 <AutoPlaySwipeableViews
		        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
		        index={activeStep}
		        onChangeIndex={handleStepChange}
		        style={{height:'inherit'}}
		      >
	        {items.map((step, index) => (
	          <React.Fragment key={step.id}>
	            {Math.abs(activeStep - index) <= 2 ? (
	              	<Item item={step} onItemSelect={onItemSelect}/>
	            ) : null}
	          </React.Fragment>
	        ))}
	      </AutoPlaySwipeableViews>
	      <Box className="flex-row flex-center">
	      	{items.map((val, index)=><div key={index} onClick={()=>setActiveStep(index)} className="dot-stepper" style={{backgroundColor:index==activeStep?"lavender":"white"}}></div>)}
	      </Box>
      </Box>
	)
}
