import React from "react";
import {Tooltip, Button} from '@mui/material';
import TableTemplate from "./TableTemplate";

import {getCount, getTransactions, updateTransaction} from "./../../services/Apis";
import {parseDate, parsePrice} from "../../helper/Parser";
const TRANSACTION_STATUS = "transaction::complete";
const tableAttributes = [
	{accessorKey: "sProductId", header: "Product Id"},
	{accessorKey: "productName", header: "Product Name"},
	{accessorKey: "price", header: "Price", Cell: ({cell})=> parsePrice(cell.getValue())} ,
	{accessorKey:"channel", header:"Channel"},
	{accessorKey: "createdDate", header: "Date", Cell:({cell}) => parseDate(cell.getValue())},
	{accessorKey: "name", header: "Buyer Name"},
	{accessorKey: "email", header: "Buyer Email"},
	{accessorKey: "walletAddress", header: "Wallet", style: {color:"#1976d2 !important"}, Cell:({cell}) => WalletCell(cell) },
];


function WalletCell(cell){
	const value = cell.getValue();
	const [copyLabel, setCopyLabel] = React.useState("Copy Wallet Address");
	const onCopy = ()=>{
		navigator.clipboard.writeText(value);
		setCopyLabel("Copied");
		setTimeout(function() {
			setCopyLabel("Copy Wallet Address");
		}, 3000);
	}
	return <Tooltip title={copyLabel}><a href="#" style={{cursor:"pointer", textDecoration: "none"}} onClick={()=>onCopy(value)}>{value.slice(0, 10) + "..." + value.slice(value.length - 5, value.length)}</a></Tooltip>
}


export default function CompleteTransaction(){
	const apiRequest = async(pagination)=>{
	 	const items = await getTransactions({params:{...pagination, status:TRANSACTION_STATUS}}).catch(()=>[]);
	 	const total = getCount();
	 	return {items, total};
	}
	return (<TableTemplate tableAttributes={tableAttributes} apiRequest={apiRequest}/>);
}

