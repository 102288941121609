import React from "react";
import Box from '@mui/material/Box';
import Product from "./Product";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {api} from "./../../services/ResourceService";
import {useMediaQuery, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import Broadcast from './../../services/Broadcast';
const broadcast = new Broadcast("z1-on-loading");

export default function Products(){
	const matches = useMediaQuery('(min-width:650px)');
	const [columns, setColumns] = React.useState(matches?3:2);
	const [products, setProducts] = React.useState([]);
	const [bg, setBg] = React.useState({opacity:1, overlayOpacity:0});
	const navigate = useNavigate();

  const onProductClick = (val)=>{
  	navigate(`/products/${val.id}`, {state:{...val}});
  }

  const onItemIn = val=>{
  	setBg({opacity: 1, overlayOpacity:0.5, backgroundImage: `url("${val.images[0].url}")`})
  }

  const onItemOut = val=>{
  		setBg({opacity:1, overlayOpacity:0});
  }

	React.useEffect(()=>{
		(async()=>{
			broadcast.postMessage(true);
			const res = await api.get("/products").catch(err=>{console.log(err); return []});
			setProducts(res);
			if(res.length<3) setColumns(res.length);
			broadcast.postMessage(false);
		})();		
	}, []);
	return (
		<Box className="flex-center" sx={{padding: "10px", boxSizing: "border-box", position: "relative"}}>
			<>
			{
				/*(<>
				<div style={{position: "fixed", width:"100vw", minHeight: "100vh", top:0, zIndex:-2, backgroundSize: "cover", transition: "all 0.5s ease-in-out", ...bg}}></div>
				<div style={{position: "fixed", width:"100vw", minHeight: "100vh", top:0, backgroundColor:"black", zIndex:-1, opacity: bg.overlayOpacity, transition: "all 1s ease-in-out"}}></div>
				</>)*/
			}
			</>
			<Box sx={{ maxWidth: "650px"}}>
				<ImageList variant="masonry" cols={columns} gap={8} sx={{overflowY:"visible"}}>
		      {products.map((item, i) => (
		        <ImageListItem key={item.id} data-aos="fade-up" data-aos-duration={1000 + i*300} onClick={()=>onProductClick(item)} className="book-item flex-center" onMouseEnter={()=>onItemIn(item)} onMouseLeave={()=>onItemOut(item)} >
								<img
									style={{objectFit: "cover", maxWidth:"400px", marginBottom:'40px'}}
									src={`${item.images[0].url}`}
									srcSet={`${item.images[0].url}`}
									alt={item.description}
									loading="lazy"
								/>
								<div className="book-item-overlay"></div>
								<Box className="flex-center" sx={{background:"rgb(255, 255, 255)", minHeight:"40px", width:"100%", zIndex:1, bottom:0, position:"absolute"}}>
										<Typography variant="title" sx={{fontWeight:600, padding: "5px"}}>{item.name}</Typography>
								</Box>
		        </ImageListItem>
		      ))}
		    </ImageList>
	    </Box>
    </Box>
	)
}