export default class Broadcast{
	constructor(channel){
		this.channel = channel + "-broadcast";
		this.callback = null;
		try{
			this.element();
		}catch(err){

		}
	}

	element(){
		this.ele = window.document.querySelector(`#${this.channel}`);
		if(!this.ele){
			this.ele = window.document.createElement('div');
			this.ele.setAttribute('id', this.channel);
			window.document.body.appendChild(this.ele);
		} 
	}

	postMessage(val){
		if(!this.ele) this.element();
		this.ele.dispatchEvent(new CustomEvent(this.channel, {detail:val, bubbles: true, cancelable: true}));
	}

	onMessage(callback){
		if(!this.callback) this.callback =(evt)=>callback(evt.detail);
		if(!this.ele) this.element();
		this.ele.addEventListener(this.channel, this.callback);
	}

	removeChannel(){
		if(!this.callback) return;
		if(!this.ele) this.element();
		this.ele.removeEventListener(this.channel, this.callback, false);
		delete this.callback;
	}

	destroy(){
		if(!this.ele) this.element();
		this.ele.remove();
	}

}