import React from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import PendingTransaction from "./PendingTransaction";
import ConfirmedTransaction from "./ConfirmedTransaction";
import CompleteTransaction from "./CompleteTransaction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const StyledTab = styled((props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('sm'));
	const label = matches?props.label:props.label.split(" ")[0];
	return <Tab disableRipple {...props}  label={label}/>
})(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    transition: 'all 1s',
    border: 'none',
    marginRight: theme.spacing(1),
    margin: '0 10px',
    fontWeight: 700,
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: '#5A9D5C'
    },
    '&:hover': {
      backgroundColor: '#d3f0d4'
    }
  }),
);

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };



  return (
    <Stack sx={{width: "100%", height:"100%"}} className="flex-center" direction="column">
    	<Box sx={{width:"100vw", maxWidth:1000}}>
	        <Tabs
			  scrollButtons="auto"
	          TabIndicatorProps={{style:{display:'none'}}}
	          value={value}
	          onChange={handleChange}
			  sx={{'& .MuiTabs-flexContainer':{justifyContent:'center'} }}
	        >
	        
	        <StyledTab label="Pending Transactions" />
	        <StyledTab label="Confirmed Transactions"  />
	        <StyledTab label="Completed Transactions" />

	        </Tabs>
	        <TabPanel value={value} index={0} dir={theme.direction}>
	          <PendingTransaction />
	        </TabPanel>
	        <TabPanel value={value} index={1} dir={theme.direction}>
	          <ConfirmedTransaction />
	        </TabPanel>
	        <TabPanel value={value} index={2} dir={theme.direction}>
	          <CompleteTransaction />
	        </TabPanel>
	    </Box>
    </Stack>
  );
}