import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {AccountContext} from './../../contexts/Account';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";
import BackButton from "../BackButton/BackButton";

export default function Login() {
  const [email, setEmail] = React.useState('');
  const [verifier, setVerifier] = React.useState({confirmationToken:'', confirmationCode:''});
  const {code, login, isLoggedIn, account, getLastVisitPage} = React.useContext(AccountContext);
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const submit = async(evt)=>{
    evt.preventDefault();
    if(error.email || error.confirmationCode) return;
    setLoading(true);
    if(!verifier.confirmationToken){
      const res = await code(email).catch(err=>setError({email:err.message}));
      setLoading(false);
      if(res && res.confirmationToken)  setVerifier({...verifier, confirmationToken:res.confirmationToken});
      return;
    } 
    const res = await login(verifier);
    navigate("/listing");
    if(!res) setError({...error, confirmationCode: "Invalid Code"});
    setLoading(false);
  }

  const onEmailChange = evt =>{
    const val = evt.target.value;
    setEmail(val);
    if(!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(val.toLowerCase()))
      return setError({...error, email:"Invalid Value"});
    delete error.email;
    setError({...error});
  }

  const onCodeChange = evt =>{
    const val = evt.target.value;
    setVerifier({...verifier, confirmationCode: val});
    delete error.confirmationCode;
    setError({...error});
  }

  React.useEffect(()=>{
    if(isLoggedIn()){
      const lastVisit = sessionStorage.getItem(process.env.REACT_APP_BROWSER_LAST_VISIT_KEY)||"/";
      window.window.href = lastVisit;
    }
  }, [account]);

  return (
    <>
    <Box sx={{padding: ["0 20px", "0px 50px", "0px 90px"], display:verifier.confirmationToken?'block':'none', height: "0px"}}>
          <BackButton onCallback={()=>setVerifier({})}/>
    </Box>
    <Box
      component="form"
      onSubmit={submit}
      sx={{
      	display:"flex",
      	flexDirection:"column",
      	justifyContent:"center",
      	alignItems: "center",
        padding: ["30px", "70px"]
      }}
    >
      <Typography mb={"20px"} variant="h4">Log in</Typography>
      <>
        {verifier.confirmationToken?(<></>):<TextField required error={error.email?true:false} helperText={error["email"]} label="Email" variant="outlined" style={{width: "100%"}} value={email} onChange={onEmailChange} data-aos="fade-right"/>}
      </>
      <>
        { verifier.confirmationToken && (<TextField required label="Enter Your Email Code" variant="outlined" style={{width: "100%"}} value={verifier.confirmationCode} onChange={(e)=>setVerifier({...verifier, confirmationCode: e.target.value})} data-aos="fade-left"/>)}
      </>      
      <Box className="flex-center" sx={{minWidth: 360, flexWrap:"wrap"}}>
        <a href="/signup" style={{textDecoration:"none", width:"100%", textAlign:"center", color: "#959595", marginBottom: "20px"}}>Don't have an account yet?</a>
        
        
        {/* <Button size="large" style={{minWidth:'100px'}} sx={{display:verifier.confirmationToken?'block':'none'}} onClick={()=>setVerifier({})}>Back</Button> */}
        <LoadingButton
        className="yellow"
          size="large" onClick={submit} style={{minWidth:'100px', fontWeight: "300", lineHeight: "1.3"}}
          loading={loading}
          variant="contained"
          disabled={(error.email || error.confirmationCode)?true:false}>
          {verifier.confirmationToken?'Log In':'Continue'}
        </LoadingButton>
      </Box>
    </Box>
    </>
  );
}