import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {Box, Stack} from '@mui/material';
import Listing from "./../Listing/Listing";
import Transactions from "./../Transactions/Transactions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTab = styled((props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('sm'));
	const label = matches?props.label:props.label.split(" ")[0];
	return <Tab disableRipple {...props}  label={label}/>
})(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    transition: 'all 1s',
    border: 'none',
    marginRight: theme.spacing(1),
    margin: '0',
    fontWeight: 700,
    color:'black',
    '&.Mui-selected': {
      backgroundColor: '#fff'
    },
    '&:hover': {
      backgroundColor: '#d3f0d4'
    }
  }),
);



export default function MainMenu() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Stack sx={{width: "100%", height:"100%"}} className="flex-center" direction="column">
	    <Box sx={{ bgcolor: 'background.paper', width:"100%", maxWidth:1000 }}>
	      <AppBar position="static" sx={{background:"#d9d9d9"}}>
	        <Tabs
	          value={value}
	          onChange={handleChange}
	          indicatorColor="secondary"
	          textColor="inherit"
	          variant="fullWidth"
	          TabIndicatorProps={{style:{display:'none'}}}
	        >
	          <StyledTab label="Listings" />
	          <StyledTab label="Transactions" />
	        </Tabs>
	      </AppBar>
	      
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Listing />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Transactions />
        </TabPanel>
	    </Box>
    </Stack>
  );
}
