import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Broadcast from './../services/Broadcast';

const broadcast = new Broadcast("z1-on-loading");


export default function ScreenProgress(){
	const [onProgress, setOnProgress] = React.useState(false);
	
	React.useEffect(()=>{
		broadcast.onMessage((data)=> {
			setOnProgress(data);
		});
	},[]);

	return (
		<Box className= "flex-center" sx={{ flexDirection:'column', width: '100vw', height:'100vh', position: 'fixed', top:0, overflow:'hidden', backgroundColor:'rgba(0,0,0,0.5)', zIndex:1000, display:onProgress?"flex":"none"}}>
			 {/*<CircularProgress sx={{ color: (theme) =>theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]}} size={60} thickness={4}/>*/}
			<img src="/book-loading2.webp" style={{maxWidth: "80px", width:"100%", minWidth: "40px"}} />
      	</Box>
	)
}