import React from "react";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {AccountContext} from './contexts/Account';

import Header from "./components/Header/Header";
import Dashboard from "./components/dashboard/Dashboard";
import MainMenu from "./components/MainMenu/MainMenu";

import Products from "./components/products/Products";
import ListingDetail from "./components/ListingDetail/ListingDetail";
import Redeem from "./components/redeem/Redeem";
import SignUp from "./components/Login/SignUp";
import Login from "./components/Login/Login";
import StripeListing from "./components/StripeListing/StripeListing";

import StripeCallback from "./components/StripeCallback/StripeCallback";
import SessionCallback from "./components/sessionCallback/SessionCallback";
import Transactions from "./components/Transactions/Transactions";
import AccountPortal from "./components/accountPortal/AccountPortal";
import Product from "./components/products/Product";
import Listing from "./components/Listing/Listing";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";



const config = {
    "listing": {
      path: "/listing",
      component:<MainMenu/>,
    },
    "listing/imports":{
      path: "/listing/imports",
      component:<StripeListing/>,
    },
    "listing/new":{
      path: "/listing/new",
      component:<ListingDetail/>,
    },
    "listing/:productId":{
      path: "/listing/:productId",
      component:<ListingDetail/>,
    },
    "portal/listing": {
      path: "/portal/listing",
      component:<Listing/>,
      trail: true
    },
    "portal/listing/imports":{
      path: "/portal/listing/imports",
      component:<StripeListing/>,
      trail: true
    },
    "portal/listing/new":{
      path: "/portal/listing/new",
      component:<ListingDetail/>,
      trail: true
    },
    "portal/listing/:productId":{
      path: "/portal/listing/:productId",
      component:<ListingDetail/>,
      trail: true
    },
    "portal/profile":{
      path: "/portal/profile",
      component:<SignUp/>,
      trail: true
    },
    "portal/transactions":{
      path: "/portal/transactions",
      component:<Transactions/>,
      trail: true
    },
    "/profile":{
      path: "/profile",
      component:<SignUp/>,
    },
    "portal":{
      path: "/portal",
      component:<AccountPortal/>,
      trail: true
    },
    "":{
      path: "*",
      component:<Navigate to='/' />,
    },
    "/":{
      path: "/",
      component:<Dashboard />,
    },
    "/dashboard":{
      path: "/dashboard",
      component:<Dashboard/>,
    },
    "/signup":{
      path: "/signup",
      component:<SignUp/>,
    },
    "/login":{
      path: "/login",
      component:<Login/>,
    },
    "/stripe_callback":{
      path: "/stripe_callback",
      component:<StripeCallback/>,
    },
    "/products/:productId/channel?/:channelId?":{
      //Channel ID is optional.
      path: "/products/:productId/channel?/:channelId?",
      component:<Product/>,
    },    
    "/session_callback/:productId":{
      path: "/session_callback/:productId",
      component:<SessionCallback/>,
    },
    "/redeem":{
      path: "/redeem",
      component:<Redeem/>,
    },
    "previews/:productId":{
      path:"/previews/:productId",
      component:<Product/>
    }
}


const Trails = ()=>{
  const [paths, setPaths] = React.useState([]);
  const location = useLocation();
  const {isLoggedIn, account, getLastVisitPage} = React.useContext(AccountContext);
  const navigate = useNavigate();

  const setup = (pathName="")=>{
    let key = "";
    const result = [];
    pathName.split("/").forEach(item=>{
        key = key?`${key}/${item}`:item;
        console.log("key::", key);
        console.log("value::", config[key]);
        if(key&&config[key]&&config[key].trail)
          result.push({path:config[key].path, name: item[0].toUpperCase() + item.substring(1)});
        else if(result.length)
          result.push({name: item[0].toUpperCase() + item.substring(1)});
        
    });
    setPaths(result);
  }

  const handleNavigate = (path)=>{
      navigate(path);
  }

  const trail = (item, index)=>{
    if(!item.path || index==paths.length-1)
      return <Typography key={index} color="text.primary" variant="h6">{item.name}</Typography>;
    return <Link underline="hover" key={index} color="inherit" onClick={()=>handleNavigate(item.path)} sx={{cursor:"pointer"}}>{item.name}</Link>;
  }

  return account && paths.length?(
    <Stack sx={{width: "100vw", marginBottom:"40px"}} className="flex-center" direction="column">
       <Breadcrumbs separator={<NavigateNextIcon fontSize="large" />} aria-label="breadcrumb" sx={{maxWidth:1200, width:"90vw"}}>
        {paths.map((item, index)=>trail(item, index))}
      </Breadcrumbs>
    </Stack>
    ):(<></>);
}

export default function PageRouter() {
  return (
    <Router>
        <Header />
        <Trails />
        <Routes>
          {Object.values(config).map((item, index)=><Route key={index} path={item.path} element={item.component} />)}
        </Routes>
    </Router>
  );
}

