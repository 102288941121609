import React from "react";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import {Stack, List, ListItem, ListItemButton, ListItemText, Checkbox, Skeleton, Typography, Button, Box} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Fab from '@mui/material/Fab';
import { green, red } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import {StripeListingContext} from "./../../contexts/StripeListingContext";

const Row = ({data, status})=>{
	const actionStyle = { 
		success:{color: "success", sx:{'&:hover': {bgcolor: green[500]}}},
	  	failure:{color:"error", sx:{'&:hover': {bgcolor: red[700]}}},
	 	progress:{color: "default",sx:{'&:hover': {bgcolor: 'transparent'}}}}

	return (
	 <>
		<ListItem primary={data.name} secondary={data.price}>
				<Box sx={{marginRight:2, position: 'relative'}}>
					<Fab color={actionStyle[status].color} size="small" sx={{...actionStyle[status].sx}}>
				        {(status=="success")?(<CheckIcon color="white"/>): 
				        (status=="progress")?(<></>):
				        (<PriorityHighIcon color="white"/>)}
				    </Fab>
				    {status=="progress" && (
				      <CircularProgress
				        sx={{
				          color: green[500],
				          position: 'absolute',
				          top:0,
				          left:0,
				          zIndex: 1060,
				        }}
				      />
				    )}
				</Box>
                <ListItemText primary={data.name} secondary={data.price} />
		</ListItem>
	 </>)
}

export default function ImportBooks({back, next}){
	const {importProduct, selectedProducts, total, data} = React.useContext(StripeListingContext);
  	const [products, setProducts] = React.useState(selectedProducts());
	const [status, setStatus] = React.useState(products.reduce((res, {id})=>{ res[id]="progress"; return res}, {}));
	const [onProgress, setOnProgress] = React.useState(false);
	const navigate = useNavigate();

	const importProducts = async()=>{
		for(let product of products){
			const res = await importProduct(product).catch(err=>{
				status[product.id] = "failure";
				return;
			});
			if(res) status[product.id] = "success";
			setStatus({...status});
			if(!res) return setOnProgress(false);
		}
		setOnProgress(false);
	}

	React.useEffect(()=>{
		importProducts();
	},[]);
	return (
	   <Stack sx={{width: "100%", height:"100%"}} className="flex-center" direction="column"> 
	   	<Typography variant="h6">Select books to import</Typography>	
		<List  sx={{ margin:5, width: '100%', maxWidth: 600, bgcolor: 'background.paper', height:400, borderRadius:"20px", overflow:"auto"}}>
	        {products.map((item, index)=><Row status={status[item.id]} data={item} key={item.id} />)}
	    </List>
	    <Stack className="flex-center" direction="row">
	    	{onProgress?(<></>):(<Button sx={{fontWeight: "300", lineHeight: "1.3"}} className="yellow" size="large" onClick={()=>navigate('/listing')}>Done</Button>)}
	    </Stack>
	   </Stack>
	)
}