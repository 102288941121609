import React from "react";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import {DashboardContext} from "./../../contexts/DashboardContext";
import { Button } from "@mui/material";
export default function NewDrops(){
	const {books, isLoading, onItemSelect} = React.useContext(DashboardContext);
	const [page, setPage] = React.useState(0);

	return (
		<Box className="new-drops flex-center flex-column">
			<Typography sx={{marginBottom:1.75}} variant="h4">BookForest new drops</Typography>
			<>{isLoading?<Loading/>:<Items page={page} items={books} onItemSelect={onItemSelect} setPage={setPage} />}</>
		</Box>)
}

function Loading(){
	console.log("its loading");
	return (
	<Box className="flex-row new-drop-items"> 
		<Box className="flex-column flex-center new-drop-item" sx={{height:"100%"}} data-aos="fade-up">
			<Skeleton variant="rectangular" width="140px" height="200px" sx={{margin:"40px 0"}} className="book-container" />
			<Skeleton width="250px" height="16px" />
			<Skeleton width="250px" height="16px" />
			<Skeleton width="250px" height="16px" />
		</Box>

		<Box className="flex-column flex-center new-drop-item" sx={{height:"100%"}} data-aos="fade-up">
			<Skeleton variant="rectangular" width="140px" height="200px" sx={{margin:"40px 0"}} className="book-container" />
			<Skeleton width="250px" height="16px" />
			<Skeleton width="250px" height="16px" />
			<Skeleton width="250px" height="16px" />
		</Box>

		<Box className="flex-column flex-center new-drop-item" sx={{height:"100%"}} data-aos="fade-up">
			<Skeleton variant="rectangular" width="140px" height="200px" sx={{margin:"40px 0"}} className="book-container" />
			<Skeleton width="250px" height="16px" />
			<Skeleton width="250px" height="16px" />
			<Skeleton width="250px" height="16px" />
		</Box>
	</Box>)
}

function Items({items, page, onItemSelect, setPage}){
	return (
		<Box className="flex-row new-drop-items">
			<>
			{	 
				items.slice(page * 11, (page * 11) + 11).map((item, id) => (
					<Item item={item} onItemSelect={onItemSelect} key={id}/>
					)
				)
			}
			{
			<Box className="flex-column new-drop-item" sx={{height:"100%"}} data-aos="fade-up">
				<Box sx={{margin:"40px 0", width:"150px", height:"200px", backgroundColor:"#D9D9D9", cursor:"default"}} className="flex-center" onClick={()=>{}}>
					<Typography variant="h4" sx={{textAlign:"center", fontWeight:600, color:"white"}}>NEW BOOK</Typography>
				</Box>
					<Typography sx={{maxWidth:"350px"}}>Get your book showcased on our BookForest website. And start selling your NFT!</Typography>
			</Box>
			}
			{
			items.length > 12 ?
			<Box sx={{marginTop: "50px"}} className="flex-row flex-center"><Button background="gray" onClick={() => setPage(page + 1)} color="primary">MORE BOOKS</Button><Button background="gray" onClick={() => setPage(page -1)} color="primary">PREVIOUS BOOKS</Button></Box>
			:
			null
			}
			</>
		</Box>
	)
}

function Item({item, onItemSelect}){
	return (
		<Box className="flex-column new-drop-item" sx={{height:"100%"}} data-aos="fade-up">
			<Box sx={{margin:"40px 0", height:"200px"}} className="new-drops-cover book-container"><img src={(item.covers[0]||{}).url} onClick={()=>{onItemSelect(item)}} style={{minWidth:"150px"}}/></Box>
			<Typography sx={{marginBottom:1.35, fontWeight: "400"}} variant="h5">{item.name || " "}</Typography>
			<Typography sx={{marginBottom:1.35, fontWeight: "370"}} variant="h6">{item.author || " "}</Typography>
			<Typography sx={{marginBottom:1.35, fontWeight: "350"}} variant="h6">{"$" + item.price  || ""}</Typography>
		</Box>
	)
}