import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import UserState from './../../states/UserState';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BackButton from "../BackButton/BackButton";
import {AccountContext} from "./../../contexts/Account";

export default function SignUp() {
  const navigate = useNavigate();
  const {account} = React.useContext(AccountContext);
  const {data, error, onChange, submit, setup} = UserState(account?({...account, privateStripe: account.stripeId?true:false}):{});
  const onCheck = ()=>{
    onChange("privateStripe", !data.privateStripe);
  }

  React.useEffect(()=>{
    setup(account?({...account, privateStripe: account.stripeId?true:false}):{})
  }, [account]);

  const isUpdateMode=()=>account&&account._id?true:false;

  return (
    <>
    <Box sx={{padding: ["45px 20px", "45px 50px", "45px 90px"], height: "0px"}}>
          <BackButton onCallback={()=> navigate(-1)}/>
    </Box>
    <Box
      component="form"
      sx={{
      	display:"flex",
      	flexDirection:"column",
      	justifyContent:"center",
      	alignItems: "center",
        padding: "20px"
      }}
    >
      <TextField required error={error["email"]?true:false} helperText={error["email"]} label="Email" variant="outlined" style={{width: "100%"}} value={data["email"]||""} onChange={(e)=>onChange("email", e.target.value||"")} InputProps={{readOnly:isUpdateMode()}}/>
      <TextField required error={error["firstName"]?true:false} helperText={error["firstName"]} label="First Name" variant="outlined" style={{width: "100%"}} value={data["firstName"]||""} onChange={(e)=>onChange("firstName", e.target.value)}/>
      <TextField required error={error["lastName"]?true:false} helperText={error["lastName"]} label="Last Name" variant="outlined" style={{width: "100%"}} value={data["lastName"]||""} onChange={(e)=>onChange("lastName", e.target.value)}/>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value={data["privateStripe"]?true:false}
          onChange={onCheck}
          control={<Checkbox checked={data["privateStripe"]?true:false}/>}
          label="I would like to connect to my own Stripe account"
          labelPlacement="end"
        />
      </FormGroup>  
      <Box className="flex-center" sx={{minWidth: 360}}>
        <Button className="yellow" disabled={error.__hasError} variant="contained" size="large" onClick={submit} style={{minWidth:'100px', fontWeight: "300", lineHeight: "1.3"}}>{isUpdateMode()?"Update":"Create"}</Button>
      </Box>
    </Box>
    </>
  );
}