import React from "react";
import {TextField, Stack, Button, Box} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import { green, red } from '@mui/material/colors';

export default function MediaEditor({data=[], onSave}){
	const [value, setValue] = React.useState(JSON.stringify(data, null, 2));
	const [message, setMessage] = React.useState("");
	const [status, setStatus] = React.useState("init");
	const actionStyle = { 
		success:{color: "success", sx:{'&:hover': {bgcolor: green[500]}}},
	  	failure:{color:"error", sx:{'&:hover': {bgcolor: red[700]}}},
	 	progress:{color: "default",sx:{'&:hover': {bgcolor: 'transparent'}}},
	 	init:{color:"secondary", sx:{}}
	}

	const onChange= (e)=>{
		setValue(e.target.value);
		if(status!=="init") setStatus("init");
		if(message) setMessage("");
	}

	const save = async ()=>{
		if(status!=="init") return;
		try{
			setStatus("progress");
			const tmp = JSON.parse(value);
			if(!Array.isArray(tmp)) throw new Error("Data must be an array");
			for(const item of tmp){
				if(typeof(item)!=="object") throw new Error("Each item must be an object");
				if(!item.url) throw new Error("item.url is required!");
			}
			await onSave(tmp);
			setStatus("success");
		}catch(err){
			setMessage(err.message);
			setStatus("failure");
		}
	}

	return (
		<Stack direction="column" className="flex-center" sx={{height:"100%"}}>
			<Box sx={{position:"relative", width:'100%', maxWidth:'600px'}}>
				<TextField
					error={message?true:false}
					helperText={message}
			        required
			        multiline
			        label="Description"
			        defaultValue={value}
			        onChange={onChange}
			        maxRows={10}
			        variant="outlined"
			        sx={{width: "100%", '& textarea':{fontSize:"14px !important"}}}
		        />
		        <Box sx={{position:"absolute", bottom: 40, right: 5}}>
			        <Box sx={{ m: 1, position: 'relative'}}>
				        <Fab color={actionStyle[status].color} size="small" sx={{...actionStyle[status].sx}} onClick={save} disabled={!value}>
				          {(status=="success")?(<CheckIcon color="white"/>): 
				           (status=="progress")?(<></>):
				           (status=="failure")?(<PriorityHighIcon color="white"/>):(<SaveIcon color="white"/>)}
				        </Fab>
				        {status=="progress" && (
				          <CircularProgress
				            sx={{
				              color: green[500],
				              position: 'absolute',
				              top:0,
				              left:0,
				              zIndex: 1060,
				            }}
				          />
				        )}
			      </Box>
			    </Box>
	        </Box>
        </Stack>
	)

}