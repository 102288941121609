import React from "react";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import "./DragDropFile.css";

export default function DragDropFile ({children, onFile}){
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);
  
  // handle drag events
  const handleDrag = function(e) {
    console.log("handleDrag::", e.type);
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") 
      return setDragActive(true);
    if (e.type === "dragleave") 
      setDragActive(false);
  };
  
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFile(e.dataTransfer.files);
    }
  };
  
  const handleChange = function(e) {
    console.log("handleChange")
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onFile(e.target.files);
    }
  };
  
  const onButtonClick = () => {
    inputRef.current.click();
  };
  
  return (
    <form id="form-file-upload" className={"flex-center " + (dragActive?"drag-active":"")} onDragEnter={handleDrag} onSubmit={(e)=>e.preventDefault()}>
      <input ref={inputRef} type="file" hidden onChange={handleChange} />
      <Box sx={{width: "100%"}}>{children}</Box>
      <Button size="large" onClick={onButtonClick}>Click to Upload</Button>
      { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </form>
  );
}