import React from "react";
import Box from '@mui/material/Box';
import { Hidden } from '@mui/material'


export default function TransactionSuccess(){
	return (
		<>
			<Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
			  <img src="/flyingbooks.png" alt="success payment" style={{maxWidth: "600px", width:"100%"}}/>
			  <h2 className={"Product-info-subtitle"}>Thank you!</h2>

			 <Hidden smUp>
			 	<p style={{color: "#000"}} className="Product-info-description">Your BookNFT will be signed <br/> and delivered to your wallet within 24 hours.</p>
			 </Hidden>
			 <Hidden smDown>
			 	<p style={{color: "#000"}}  className="Product-info-description">Your BookNFT will be signed and delivered to your wallet within 24 hours.</p>
			 </Hidden>
			</Box>
		 
		</>
	)
}