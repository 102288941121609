function parseDate(dateTime) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  if (dateTime) {
    const date = new Date(dateTime);
    return date.toLocaleString();
  }
  return "N/A";
};

function parsePrice(price) {
  if (price) {
    return `$${price}`;
  }
  return "N/A";
}

export {parseDate, parsePrice};