import React from "react";
import {Card, Typography, CardMedia, CardContent, Stack, styled, Box, useTheme, useMediaQuery} from '@mui/material';
import { useNavigate } from "react-router-dom";

import ListingImg from "./../../assets/book-stack.png";
import TransactionImg from "./../../assets/transaction.png";
import PersonalInfoImg from "./../../assets/personal-information.png";
import { AccountContext } from "./../../contexts/Account";

const StyledCardMedia = styled((props) =>(
		<Stack className="flex-center" direction="row" sx={{padding:1}}>
			<Box sx={{position:"relative", height:20, width:100}}>
				<CardMedia className="styled-card-media" {...props}/>
			</Box>
		</Stack>
	))(
  ({ theme }) => ({
       transition: 'all 0.5s',
       height:100, position:"absolute", bottom:0, left:0, width:100,
    
  }))

const PortalCard = styled((props)=>(
	<Box sx={{height:200, margin: '0 20px'}}>
		<Card {...props} >
			<CardContent>
				<StyledCardMedia image={props.item.logo} title={props.item.title}/>
				<Typography variant="subtitle1" component="div" align="center" >
					{props.item.title}
				</Typography>
			</CardContent>
	</Card>
	</Box>
))(
  ({ theme }) => ({
  	width:250,
  	height:100, 
  	overflow:"visible", 
  	borderRadius:15,
    transition: 'all 0.5s',
    cursor:'pointer',
    '&:hover': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)'
    },
    '&:hover .MuiCardMedia-root':{
    	transform: "scale(1.1)"
    }

  }));



const items = [
	{logo: ListingImg, title: "Your Listing", path: "/portal/listing"},
	{logo: TransactionImg, title: "Transactions", path: "/portal/transactions"},
	{logo: PersonalInfoImg, title: "Personal Information", path: "/portal/profile"},
]


export default function AccountPortal(){
	const navigate = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('md'));
	const {isLoggedIn, account} = React.useContext(AccountContext);
	const onOption = (item, index)=>{
		return navigate(item.path, {state: (item.path=="/portal/profile")?{...account}:{}})
	}

	return (
		<Stack sx={{width: "100%", height:"100%"}}  className="flex-center" direction="column">
			<Stack sx={{maxWidth:1200, minHeight:"80vh", padding: 10, flexWrap:"wrap"}} className="flex-center" direction="row">
			{items.map((item,index)=>(
				<PortalCard item={item} key={index} onClick={()=>onOption(item, index)}/>
			))}
			</Stack>
		</Stack>

	)
}