import React from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Stack from '@mui/material/Stack';
import {AccountContext} from './../../contexts/Account';
import { WalletContext } from "../../contexts/Wallet";
import ProfileMenu from "./ProfileMenu";
import Menu from '@mui/material/Menu';
import { useNavigate } from "react-router-dom";

export default function Header() {
    const navigate = useNavigate();
    const wallet = React.useContext(WalletContext);
    const {isLoggedIn, account} = React.useContext(AccountContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () =>setAnchorEl(null);

    const onMainScreen = ()=>{
        const path = window.location.pathname;
        if(path=="/") return navigate(0);
        navigate("/");
    }

    return (
        <header className="header">
            <Box sx={{display: "flex", flexDirection: ["column", "column", "row"], justifyContent: ["center", "center", "space-between"], alignItems: ["center", "center", "start"]}}>
                <a style={{textDecoration: "none", fontSize: "24px", lineHeight: "40px", letterSpacing:1, cursor:"pointer"}} onClick={onMainScreen}>
                    <b style={{color: "black"}}>Book</b><span style={{color: "#5a9d5c", fontWeight:600}}>Forest</span>
                </a>
                
                <Stack direction="row" spacing={2}>
                    <>{
                        (!wallet.chainNetwork.account.address)?
                        (<Button onClick={()=>wallet.chainNetwork.onWalletChange()} startIcon={<PublicOffIcon sx={{color:"lightgrey"}}/>}>Connect Your Wallet</Button>):
                        (<Button startIcon={<PublicIcon className="neon-light"/>}>{"Wallet: " + wallet.chainNetwork.account.address.substring(0, 7) +"..." +wallet.chainNetwork.account.address.substring(wallet.chainNetwork.account.address.length - 4)}</Button>)
                    }</>
                    <>{
                        account?
                        (<>
                            <Button startIcon={<AccountCircleIcon/>} onClick={handleMenuClick}>{account.firstName + " " + account.lastName}</Button>
                            <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                                <ProfileMenu />
                            </Menu>
                        </>):<><Button onClick={() => navigate("/login")}>AUTHOR LOG IN</Button></>
                    }</>
                    
                </Stack>    			          
            </Box>
        </header>
    )
}