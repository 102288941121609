import React from "react";

import { useNavigate } from "react-router-dom";
import {SvgIcon} from '@mui/material';
import {ReactComponent as BackButtonIcon} from "../../assets/back_button.svg";


export default function BackButton({path, onCallback}) {
    const navigator = useNavigate();

    const navigate = () => {
        if (path) {
            navigator(path)
            return;
        }
        if (onCallback) {
            onCallback();
            return;
        }
        navigator(-1);
    }
    return (
        <a style={{ marginBottom: "10px"}} onClick={navigate} href="#"><SvgIcon sx={{height: "50px"}} component={BackButtonIcon} size="large"/></a>
    )
}